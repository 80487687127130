import React, { FC, useState, useRef, useEffect } from 'react';
import { Dialog, useMediaQuery, useTheme, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useConfirm } from 'material-ui-confirm';
import CancelIcon from '@mui/icons-material/Cancel';
import PostCatchBody from '../../../../../Models/CatchRegistrationModels/PostCatchBody';
import CatchRegistration from '../../../../../Models/CatchRegistrationModels/CatchRegistration';
import CatchCombination from '../../../../../Models/CatchRegistrationModels/CatchCombination';
import FishingArea from '../../../../../Models/CatchRegistrationModels/FishingArea';
import StorageMethod from '../../../../../Models/CatchRegistrationModels/StorageMethod';
import Destiny from '../../../../../Models/CatchRegistrationModels/Destiny';
import FishingStock from '../../../../../Models/CatchRegistrationModels/FishingStock';
import Condition from '../../../../../Models/CatchRegistrationModels/Condition';
import Gear from '../../../../../Models/WeightNoteModels/Gear';
import WeightNote from '../../../../../Models/WeightNoteModels/WeightNote';
import { MobilePaperComponent, PaperComponent } from '../../../../../SharedComponents/Paper/CustomPaper';
import CatchRegistrationDefaults from '../../../../../Constants/CatchRegistrationDefaults';
import CatchRegistrationCatch from '../CatchRegistrationCatch/CatchRegistrationCatch';
import CatchRegistrationWeight from '../CatchRegistrationWeight/CatchRegistrationWeight';

interface CatchRegistrationSplitModalProps {
  open: boolean;
  weightItem: CatchRegistration;
  handleClose: () => void;
  handleSplit: (formData: PostCatchBody) => Promise<void>;
  catchCombinations: CatchCombination[] | null;
  fishAreas: FishingArea[];
  storageMethods: StorageMethod[];
  destinies: Destiny[];
  fishingStocks: FishingStock[];
  conditions: Condition[];
  gears: Gear[];
  selectedWeightNote: WeightNote;
  showSnackbar: (message: string, severity: string) => void;
}

const CatchRegistrationSplitModal: FC<CatchRegistrationSplitModalProps> = (props) => {
  const [formData, setFormData] = useState<PostCatchBody>({});
  const [loading, setLoading] = useState<boolean>(false);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const confirm = useConfirm();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && !(loading ||
        !formData.fishStockId ||
        !formData.fishingAreaId ||
        !formData.storageMethodId ||
        !formData.destinyId ||
        !formData.conditionId ||
        !formData.weight ||
        formData.weight <= 0)) {
        event.preventDefault();
        if (submitButtonRef.current) {
          submitButtonRef.current.click();
        }
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [formData.conditionId, formData.destinyId, formData.fishStockId, formData.fishingAreaId, formData.storageMethodId, formData.weight, loading]);

  const handleFormChange = (updatedFormData: PostCatchBody) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      ...updatedFormData
    }));
  };

  const handleSubmit = async () => {
    if (formData) {
      try {
        setLoading(true);
        await props.handleSplit(formData);
        props.showSnackbar('Vigtun skipt!', 'success');
        setLoading(false);
        props.handleClose();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        setLoading(false);
        if (error.response && error.response.data && typeof error.response.data !== 'object') {
          const errorMsg = error.response.data;
          props.showSnackbar(errorMsg, 'error');
        } else {
          props.showSnackbar('Óvænt villa kom upp!', 'error');
        }
      }
    }
  };

  const closeHandler = () => {
    if (!loading) {
      if (Object.keys(formData)?.length > 3) {
        confirm({
          title: 'Villtu loka glugganum?',
          description: `Þú ert með óvistaðar breytingar.`,
          confirmationText: 'Loka',
          cancellationText: 'Hætta við',
          confirmationButtonProps: { variant: 'contained', color: 'primary' },
          cancellationButtonProps: { variant: 'outlined' },
        })
          .then(() => {
            setFormData({});
            props.handleClose();
          })
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          .catch(() => { });
      } else {
        setFormData({});
        props.handleClose();
      }
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={closeHandler}
      disableEscapeKeyDown={loading}
      PaperComponent={isMobile || isTablet ? MobilePaperComponent : PaperComponent}
      aria-labelledby="split-dialog-title"
      maxWidth='sm'
    >
      <div id="split-dialog-title" style={{ fontSize: '1.5em', fontWeight: 'bold', lineHeight: '1.5em', margin: '0', cursor: 'move' }}>
        Skipta vigt
        <IconButton size='large' sx={{ position: 'absolute', top: 0, right: 0 }} onClick={closeHandler}>
          <CancelIcon fontSize='large' />
        </IconButton>
      </div>
      <div>
        <CatchRegistrationCatch
          disabled={loading}
          handleFormChange={handleFormChange}
          allowedCombinations={props.catchCombinations}
          weight={undefined}
          selectedWeightNote={props.selectedWeightNote}
          fishAreas={props.fishAreas}
          storageMethods={props.storageMethods}
          destinies={props.destinies}
          fishingStocks={props.fishingStocks}
          conditions={props.conditions}
          gears={props.gears}
          containsExported={props.selectedWeightNote.weights?.some(weight => weight.destiny?.id === CatchRegistrationDefaults.DESTINY_EXPORTED_ID) ?? false}
        />
        <CatchRegistrationWeight
          handleFormChange={handleFormChange}
          weightItem={undefined}
          disabled={props.selectedWeightNote.isClosed}
          selectedWeightNote={props.selectedWeightNote}
          isExported={
            formData.destinyId === CatchRegistrationDefaults.DESTINY_EXPORTED_ID ||
            (props.weightItem?.destiny?.id === CatchRegistrationDefaults.DESTINY_EXPORTED_ID && !formData.destinyId)
          }
          stockIsScallops={
            formData.fishStockId === CatchRegistrationDefaults.FISHING_STOCK_SCALLOPS_ID ||
            (props.weightItem?.fishingStock?.id === CatchRegistrationDefaults.FISHING_STOCK_SCALLOPS_ID && !formData.fishStockId)
          }
          splitWeight={props.weightItem?.weight}
        />
        <div style={{ width: '100%', marginTop: '1em' }}>
          <LoadingButton
            fullWidth
            size="small"
            onClick={handleSubmit}
            loading={loading}
            disabled={
              loading ||
              !formData.fishStockId ||
              !formData.fishingAreaId ||
              !formData.storageMethodId ||
              !formData.destinyId ||
              !formData.conditionId ||
              !formData.weight ||
              formData.weight <= 0
            }
            color='primary'
            variant="contained"
            ref={submitButtonRef}
          >
            Skipta vigt
          </LoadingButton>
        </div>
      </div>
    </Dialog>
  );
};

export default CatchRegistrationSplitModal;