import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { ItemRow, TitleFont, StyledTextField, AdminLandingModalWrapper } from './AdminLandingModal.styled';
import { Autocomplete, Dialog, IconButton, InputAdornment, TextField, useMediaQuery, useTheme } from '@mui/material';
import Ship from '../../../../Models/ShipModels/Ship';
import { patchLanding } from '../../../../Services/LondunarkerfiAPIService';
import { DatePicker, DateValidationError, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/is';
import Landing from '../../../../Models/LandingModels/Landing';
import LandingType from '../../../../Models/LandingModels/LandingType';
import LandingSubmit from '../../../../Models/LandingModels/LandingSubmit';
import ArticleIcon from '@mui/icons-material/Article';
import CancelIcon from '@mui/icons-material/Cancel';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { LoadingButton } from '@mui/lab';
import WeightNote from '../../../../Models/WeightNoteModels/WeightNote';
import User from '../../../../Models/UserModels/User';
import UserHarbours from '../../../../Models/UserModels/UserHarbours';
import ShipAutoComplete from '../../../../SharedComponents/AutoComplete/ShipAutoComplete';
import { MobilePaperComponent, PaperComponent } from '../../../../SharedComponents/Paper/CustomPaper';
import { logError } from '../../../../Helpers/LogError';

interface AdminLandingModalProps {
  adminLandingModalOpen: boolean;
  toggleAdminLandingModalOpen: () => void;
  selectedLanding: Landing;
  selectedWeightNote: WeightNote;
  landingTypes: LandingType[];
  harbours: UserHarbours[];
  fetchLandings: (setSelectedLandingId?: number, setSelectedWeightNoteId?: number) => void;
  showSnackbar: (message: string, severity: string) => void;
  user: User;
}

/**
 * Functional component for admin modal for landing.
 * Displays a modal for editing a already completed landing.
 * @param props 
 * - takes in various values used for dropDown values.
 * - takes in the selected landing and the selected weightNote.
 * - takes in a function to fetch landings.
 * @returns {JSX} - Responsible for returning the modal which is used to edit a specific landing.
 */

const AdminLandingModal: FC<AdminLandingModalProps> = (props: AdminLandingModalProps) => {

  const [ship, setShip] = useState<Ship | null>(null);
  const [harbour, setHarbour] = useState<UserHarbours | null>(null);
  const [landingType, setLandingType] = useState({} as LandingType | null);
  const [landingStarts, setLandingStarts] = useState<Dayjs | null>(dayjs());
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [dateError, setDateError] = useState<DateValidationError | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const initiateLandingFormData = () => {
      if (!props.selectedLanding.id) {
        return;
      }
      setShip(props.selectedLanding.ship);
      setLandingType(props.selectedLanding.landingType);
      setLandingStarts(dayjs(props.selectedLanding.landingStarts));
      setHarbour(props.selectedLanding.harbour);
    };
    initiateLandingFormData();
  }, [props.selectedLanding]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && isFormChanged) {
        event.preventDefault();
        if (submitButtonRef.current) {
          submitButtonRef.current.click();
        }
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [isFormChanged]);

  const handleTypeChange = (selectedLandingType: LandingType | null) => {
    setLandingType(selectedLandingType);
    setIsFormChanged(false);
    if (selectedLandingType?.id !== props.selectedLanding.landingType.id) {
      setIsFormChanged(true);
    }
  }

  const handleShipChange = (selectedShip: Ship | null) => {
    setShip(selectedShip);
    setIsFormChanged(false);
    if (selectedShip?.shipRegistrationNumber !== props.selectedLanding.ship.shipRegistrationNumber) {
      setIsFormChanged(true);
    }
  }

  const handleLandingStartsChange = (landingStarts: Dayjs | null) => {
    setLandingStarts(landingStarts);
    setIsFormChanged(false);
    if (!landingStarts?.isSame(props.selectedLanding.landingStarts)) {
      setIsFormChanged(true);
    }
  }

  const handleHarbourChange = (selectedHarbour: UserHarbours | null) => {
    setHarbour(selectedHarbour);
    setIsFormChanged(false);
    if (selectedHarbour?.id !== props.selectedLanding.harbour.id) {
      setIsFormChanged(true);
    }
  }

  const handleSubmit = () => {
    updateClosedLanding();
  }

  async function updateClosedLanding() {
    try {
      setLoading(true);
      const editBody: LandingSubmit = {
        landingTypeId: landingType?.id,
        landingStarts: landingStarts?.toDate(),
        shipNumber: ship?.shipRegistrationNumber,
        revokeAndCreateNew: true
      };
      if (props.selectedLanding.harbour.id !== harbour?.id) {
        editBody.changeToHarbourId = harbour?.id;
      }
      await patchLanding(props.selectedLanding.id, editBody);
      props.showSnackbar('Löndun uppfærð!', 'success');
      props.toggleAdminLandingModalOpen();
      props.fetchLandings(props.selectedLanding.id, props.selectedWeightNote.id);
      setLoading(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setLoading(false);
      if (error.response && error.response.data && typeof error.response.data !== 'object') {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');
      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp!', 'error');
      }
    }
  }

  const dateErrorMsg = useMemo(() => {
    switch (dateError) {
      case 'maxDate': {
        return 'Löndun má ekki vera í framtíðinni!';
      }
      case 'invalidDate': {
        return 'Ógild dagsetning!';
      }
      case 'shouldDisableDate': {
        return 'Veldu dagsetningu!';
      }
      default: {
        return '';
      }
    }
  }, [dateError]);

  return (
    <AdminLandingModalWrapper>
      <Dialog
        open={props.adminLandingModalOpen}
        onClose={!loading ? props.toggleAdminLandingModalOpen : undefined}
        disableEscapeKeyDown={loading}
        PaperComponent={isMobile || isTablet ? MobilePaperComponent : PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >

        <TitleFont id="draggable-dialog-title">
          Breyta lokaðri löndun
          <IconButton size='large' sx={{ position: 'absolute', top: 0, right: 0 }} onClick={props.toggleAdminLandingModalOpen}>
            <CancelIcon fontSize='large' />
          </IconButton>
        </TitleFont>

        <ItemRow>
          <Autocomplete
            sx={{ width: '50%' }}
            disablePortal
            disabled={loading}
            options={props.landingTypes}
            getOptionLabel={(option) => option.name || ""}
            value={landingType ? landingType : null}
            onChange={(e, value) => handleTypeChange(value)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            slotProps={{
              popper: {
                disablePortal: false,
              }
            }}
            renderInput={(params) =>
              <StyledTextField
                label="Gerð löndunar"
                {...params}
                InputProps={{
                  ...params.InputProps,
                  required: true,
                  startAdornment: (
                    <InputAdornment position='start'>
                      <ArticleIcon />
                    </InputAdornment>
                  )
                }}
                required
                error={(!landingType || landingType.id === undefined)}
                helperText={(!landingType || landingType.id === undefined) ? 'Veldu Landanagerð' : ''}
              />}
          />

          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='is'>
            <DatePicker
              disabled={loading}
              label='Löndun hefst'
              sx={{ width: '50%' }}
              value={landingStarts}
              onChange={(newValue: Dayjs | null) => handleLandingStartsChange(dayjs(newValue))}
              format="DD.MM.YYYY"
              onError={(newError) => setDateError(newError)}
              slotProps={{
                textField: {
                  helperText: dateErrorMsg,
                  error: dateError !== null,
                  required: true
                },
              }}
              maxDate={dayjs()}
            />
          </LocalizationProvider>
        </ItemRow>

        <ShipAutoComplete onShipChange={handleShipChange} selectedShip={ship} autofocus={false} />

        <TextField
          id="outlined-multiline-static"
          label="Seljandi"
          value={ship ?
            ship?.sellerName + "\n" +
            ship?.sellerSSN + "\n" +
            ship?.sellerPlace + "\n" +
            ship?.sellerAddress
            : ''
          }
          multiline
          disabled
          rows={4}
        />

        <Autocomplete
          disablePortal
          disabled={loading}
          options={props.harbours.sort((a, b) => a.harbourName.localeCompare(b.harbourName))}
          getOptionLabel={(option) => option.harbourName || ""}
          onChange={(e, value) => handleHarbourChange(value)}
          value={harbour ? harbour : null}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          slotProps={{
            popper: {
              disablePortal: false,
            }
          }}
          renderInput={(params) =>
            <StyledTextField
              label="Höfn"
              {...params}
              InputProps={{
                ...params.InputProps,
                readOnly: true,
                startAdornment: (
                  <InputAdornment position='start'>
                    <AccountBalanceIcon />
                  </InputAdornment>
                )
              }}
            />}
        />

        <LoadingButton
          ref={submitButtonRef}
          size="small"
          disabled={!isFormChanged || props.user?.role.id === Number(process.env.REACT_APP_ADMIN_ROLE_ID)}
          onClick={handleSubmit}
          loading={loading}
          variant="contained"
        >
          <span>Breyta lokaðri löndun</span>
        </LoadingButton>
      </Dialog>
    </AdminLandingModalWrapper>
  );
}

export default AdminLandingModal;