import React, { FC, useState } from 'react';
import { CatchRegistrationDeductionWrapper } from './CatchRegistrationDeduction.styled';
import { Box, Button, Card, CardContent, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { deleteDeduction, patchWeight } from '../../../../../Services/LondunarkerfiAPIService';
import CatchDeduction from '../../../../../Models/CatchRegistrationModels/CatchDeduction';
import User from '../../../../../Models/UserModels/User';
import { useConfirm } from 'material-ui-confirm';
import CatchRegistration from '../../../../../Models/CatchRegistrationModels/CatchRegistration';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import AddIcon from '@mui/icons-material/Add';
import { DataGrid, GridColDef, GridActionsCellItem } from '@mui/x-data-grid';
import DeductionType from '../../../../../Models/CatchRegistrationModels/DeductionType';
import WeightNote from '../../../../../Models/WeightNoteModels/WeightNote';
import CatchRegistrationDeductionModal from '../CatchRegistrationDeductionModal/CatchRegistrationDeductionModal';
import { Carousel } from 'react-responsive-carousel';
import CircleIcon from '@mui/icons-material/Circle';
import WeightNoteTypes from '../../../../../Constants/WeightNoteTypes';
import Vehicle from '../../../../../Models/CatchRegistrationModels/Vehicle';
import PostCatchBody from '../../../../../Models/CatchRegistrationModels/PostCatchBody';
import CatchRegistrationDefaults from '../../../../../Constants/CatchRegistrationDefaults';
import { logError } from '../../../../../Helpers/LogError';

interface CatchRegistrationDeductionProps {
  deductionModalOpen: boolean;
  toggleDeductionModalOpen: () => void;
  weightItem: CatchRegistration | undefined;
  selectedWeightNote: WeightNote;
  user: User;
  setNewWeightItem: (weightId: number, formData?: PostCatchBody) => Promise<void>;
  deductionTypes: DeductionType[];
  tempDeductions: CatchDeduction[];
  setTempDeductions: (deductions: CatchDeduction[]) => void;
  showSnackbar: (message: string, severity: 'success' | 'error') => void;
  vehicles: Vehicle[];
  formData: PostCatchBody;
  refetchVehicles: () => Promise<void>;
}

/**
 * Functional component for CatchRegistrationDeduction.
 * Displays the deduction section of the catch registration modal.
 * @param props 
 * - takes in a weightItem object
 * - takes in a selectedWeightNote object
 * - takes in a user object
 * - takes in a setNewWeightItem function
 * - takes in a deductionTypes array
 * - takes in a tempDeductions array
 * - takes in a setTempDeductions function
 * - takes in a showSnackbar function
 * @returns {JSX} - Responsible for returning the deduction section of the catch registration modal.
 */

const CatchRegistrationDeduction: FC<CatchRegistrationDeductionProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [deductionId, setDeductionId] = useState<number | undefined>(undefined);
  const confirm = useConfirm();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const resetDeductionItem = () => {
    setDeductionId(undefined);
  };

  const handleDeductionDelete = (deductionId: number) => () => {
    confirm({
      title: 'Ertu alveg viss?',
      description: `Þú ert að fara eyða frádrætti.`,
      confirmationText: 'Eyða',
      cancellationText: 'Hætta við',
      confirmationButtonProps: { variant: 'contained', color: 'error' },
      cancellationButtonProps: { variant: 'outlined' },
    })
      .then(() => delDeduction(deductionId))
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => { });
  };

  async function delDeduction(deductionId: number) {
    try {
      setLoading(true);
      if (props.weightItem) {
        if (registeredVehicle?.id && deductionId === -1) {
          const updateWeight: PostCatchBody = {
            vehicleId: undefined,
            vehicleDeduction: undefined,
            shouldClearVehicle: true
          };
          await patchWeight(props.weightItem.id, updateWeight);
          await props.setNewWeightItem(props.weightItem.id);
          props.showSnackbar('Flutningstæki fjarlægt!', 'success');
        } else {
          await deleteDeduction(props.weightItem.id, deductionId);
          await props.setNewWeightItem(props.weightItem.id);
          props.showSnackbar('Frádrætti eytt!', 'success');
        }
      } else {
        const newDeductions = props.tempDeductions.filter(deduction => deduction.id !== deductionId);
        props.setTempDeductions(newDeductions);
      }
      setLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.response && error.response.data && typeof error.response.data !== 'object') {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');
        setLoading(false);
      }
      else {
        logError(error);
        setLoading(false);
        props.showSnackbar('Óvænt villa kom upp', 'error');
      }
    }
  }

  const columns = React.useMemo<GridColDef<CatchDeduction>[]>(() => [
    { field: 'deductionType', headerName: 'Tegund frádráttar', type: 'string', editable: true, align: 'left', flex: 1, },
    { field: 'unit', headerName: 'Fjöldi', type: 'number', editable: true, align: 'right', flex: 0.75, },
    { field: 'weight', headerName: 'Þyngd', type: 'number', editable: true, align: 'right', flex: 0.75, },
    { field: 'quantity', headerName: 'Magn', type: 'number', editable: true, align: 'right', flex: 0.75, },
    {
      field: 'actions',
      type: 'actions',
      width: 1,
      getActions: (params) => {
        if (props.selectedWeightNote.isClosed) {
          return [];
        }
        return [<GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          color="error"
          onClick={handleDeductionDelete(params.id as number)}
        />];
      },
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [props.weightItem, props.tempDeductions]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRowClickChild = (params: any) => {
    const rowData = params.row as CatchDeduction;

    setDeductionId(rowData.id);
    props.toggleDeductionModalOpen();
  }

  const handleDeductionEdit = (deductionId: number) => {
    setDeductionId(deductionId);
    props.toggleDeductionModalOpen();
  };

  const DeductionCarousel = () => {
    if (props.tempDeductions.length === 0 && !props.weightItem?.deductions) {
      return <Typography variant='body2' sx={{ textAlign: 'center', paddingTop: '2em' }}>Engir frádrættir</Typography>;
    }
    return (
      <Carousel
        autoFocus
        useKeyboardArrows
        labels={{ leftArrow: 'Fyrri frádráttur', rightArrow: 'Næsti frádráttur', item: 'Frádráttur' }}
        autoPlay={false}
        infiniteLoop
        showStatus={false}
        showThumbs={false}
        showArrows={false}
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          const defStyle = { color: "#CCCCCC", cursor: "pointer", padding: "0.3em" };
          const style = isSelected
            ? { ...defStyle, color: "black" }
            : { ...defStyle };
          return (
            <span
              style={style}
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              key={index}
              role="button"
              tabIndex={0}
              aria-label={`${label} ${index + 1}`}
            >
              <CircleIcon sx={{ fontSize: '0.7em' }} />
            </span>
          );
        }}
      >
        {props.weightItem ? props.weightItem?.deductions.map((deduction, index) => (
          <Card
            key={index}
            sx={{
              marginBottom: '2.5em',
              cursor: 'pointer',
              borderRadius: '1em',
            }}
          >
            <CardContent onClick={() => handleDeductionEdit(deduction.id)}>
              <Typography variant='h6' sx={{ textAlign: 'center' }}>{deduction.deductionType}</Typography>
              <Typography variant='body1' sx={{ textAlign: 'center' }}>Fjöldi: {deduction.unit}</Typography>
              <Typography variant='body1' sx={{ textAlign: 'center' }}>Þyngd: {deduction.weight}</Typography>
              <Typography variant='body1' sx={{ textAlign: 'center' }}>Magn: {deduction.quantity}</Typography>
            </CardContent>
            {props.selectedWeightNote.isClosed || loading || props.user?.role.id === Number(process.env.REACT_APP_ROLE_READ_ID) ? null : (
              <IconButton
                aria-label="delete"
                onClick={handleDeductionDelete(deduction.id)}
                sx={{ width: '100%' }}
              >
                <DeleteIcon color='error' />
              </IconButton>
            )}
          </Card>
        )) : props.tempDeductions.map((deduction, index) => (
          <Card
            key={index}
            sx={{
              marginBottom: '2.5em',
              cursor: 'pointer',
              borderRadius: '1em',
            }}
          >
            <CardContent onClick={() => handleDeductionEdit(deduction.id)}>
              <Typography variant='h6' sx={{ textAlign: 'center' }}>{deduction.deductionType}</Typography>
              <Typography variant='body1' sx={{ textAlign: 'center' }}>Fjöldi: {deduction.unit}</Typography>
              <Typography variant='body1' sx={{ textAlign: 'center' }}>Þyngd: {deduction.weight}</Typography>
              <Typography variant='body1' sx={{ textAlign: 'center' }}>Magn: {deduction.quantity}</Typography>
            </CardContent>
            {props.selectedWeightNote.isClosed || loading || props.user?.role.id === Number(process.env.REACT_APP_ROLE_READ_ID) ? null : (
              <IconButton
                aria-label="delete"
                onClick={handleDeductionDelete(deduction.id)}
                sx={{ width: '100%' }}
              >
                <DeleteIcon color='error' />
              </IconButton>
            )}
          </Card>
        ))}
      </Carousel>
    );
  };

  const registeredVehicle = props.weightItem?.vehicle;
  const vehicleDeductionFaker: CatchDeduction = {
    id: -1,
    deductionType: `Flutningstæki (${registeredVehicle?.vehicleRegistrationNumber})`,
    unit: 1,
    weight: props.weightItem?.carDeduction || 0,
    quantity: props.weightItem?.carDeduction || 0,
    weightId: props.weightItem ? props.weightItem.id : 0,
    shouldWithdraw: false,
    deductionTypeId: CatchRegistrationDefaults.TRANSPORT_VEHICLE_DEDUCTION_TYPE_ID,
    vehicleId: registeredVehicle?.id || 0
  };
  const deductionRows = props.weightItem && props.weightItem.vehicle?.id && vehicleDeductionFaker
    ? [...props.weightItem.deductions, vehicleDeductionFaker]
    : props.weightItem
      ? props.weightItem.deductions
      : props.tempDeductions;

  return (
    <CatchRegistrationDeductionWrapper>
      <Button
        aria-label="new-deduction"
        variant='contained'
        color='primary'
        size='small'
        onClick={props.toggleDeductionModalOpen}
        sx={{ height: '2.5em', display: 'flex', alignItems: 'center', gap: '0.5em', borderRadius: 10, width: '100%' }}
        disabled={
          props.deductionModalOpen ||
          props.selectedWeightNote.isClosed ||
          loading ||
          props.user?.role.id === Number(process.env.REACT_APP_ROLE_READ_ID)}
      >
        <AddIcon fontSize='small' />
        Nýr frádráttur
      </Button>
      <CatchRegistrationDeductionModal
        open={props.deductionModalOpen}
        toggleOpen={props.toggleDeductionModalOpen}
        deductionTypes={props.deductionTypes}
        selectedWeightNote={props.selectedWeightNote}
        weightItem={props.weightItem}
        deductionId={deductionId}
        setNewWeightItem={props.setNewWeightItem}
        resetDeductionItem={resetDeductionItem}
        showSnackbar={props.showSnackbar}
        setTempDeductions={props.setTempDeductions}
        tempDeductions={props.tempDeductions}
        vehicles={props.vehicles}
        user={props.user}
        formData={props.formData}
        refetchVehicles={props.refetchVehicles}
      />
      {(isMobile || isTablet) && (props.weightItem?.deductions || props.tempDeductions) ? <div style={{ width: '100%' }}>{DeductionCarousel()}</div> : (
        <Box sx={{ width: '100%', height: props.selectedWeightNote.weightNoteType.id === WeightNoteTypes.PRODUCT_ID ? '14em' : '14em' }}>
          <DataGrid
            columns={columns}
            rows={deductionRows}
            loading={loading}
            pageSizeOptions={[5]}
            onRowClick={handleRowClickChild}
            hideFooterPagination
            hideFooterSelectedRowCount
            hideFooter
            disableColumnMenu
            slots={{
              noRowsOverlay: () => <Typography variant='body2' sx={{ textAlign: 'center', paddingTop: '2em' }}>Engir frádrættir</Typography>
            }}
          />
        </Box>
      )}
    </CatchRegistrationDeductionWrapper>
  );
};

export default CatchRegistrationDeduction;