import { FC, useEffect, useRef, useState } from 'react';
import { Box, Chip, Dialog, Divider, FormControl, IconButton, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField, Tooltip, Typography } from '@mui/material';
import Landing from '../../../../Models/LandingModels/Landing';
import WeightNote from '../../../../Models/WeightNoteModels/WeightNote';
import { LoadingButton } from '@mui/lab';
import CancelIcon from '@mui/icons-material/Cancel';
import Email from '../../../../Models/EmailModels/Email';
import QueueIcon from '@mui/icons-material/Queue';
import EmailModal from '../../../../SharedComponents/Header/components/EmailModal/EmailModal';
import { getEmailGroups, getEmails, sendEmailWeightNote } from '../../../../Services/LondunarkerfiAPIService';
import EmailSend from '../../../../Models/EmailModels/EmailSend';
import User from '../../../../Models/UserModels/User';
import EmailGroup from '../../../../Models/EmailModels/EmailGroup';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonIcon from '@mui/icons-material/Person';
import DescriptionIcon from '@mui/icons-material/Description';
import dayjs from 'dayjs';
import { MobilePaperComponent, PaperComponent } from '../../../../SharedComponents/Paper/CustomPaper';
import { logError } from '../../../../Helpers/LogError';

interface SendWeightNoteModalProps {
  user: User;
  open: boolean;
  toggleOpen: () => void;
  showSnackbar: (message: string, severity: string) => void;
  landing: Landing;
  weightNote: WeightNote;
  isMobileView: boolean;
}

/**
 * Funcitonal component for SendWeightNoteModal
 * @param {SendWeightNoteModalProps} props 
 * @returns {JSX} renders a modal that allows the user to send a weight note to an email or email group
 * 
 * Responsible for rendering a modal that allows the user to send a weight note to an email or email group
 */

const SendWeightNoteModal: FC<SendWeightNoteModalProps> = (props: SendWeightNoteModalProps) => {
  const [emails, setEmails] = useState<Email[]>([]);
  const [emailGroups, setEmailGroups] = useState<EmailGroup[]>([]);
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [selectedEmailGroups, setSelectedEmailGroups] = useState<string[]>([]);
  const [subject, setSubject] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [openEmailControl, setOpenEmailControl] = useState<boolean>(false);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (props.user && props.open) {
          const fetchedEmails = await getEmails(props.user?.userPickedHarbour);
          setEmails(fetchedEmails.sort((a, b) => a?.email.localeCompare(b?.email)));

          const fetchedEmailGroups = await getEmailGroups(props.user?.userPickedHarbour);
          const filteredEmailGroups = fetchedEmailGroups.filter(group => group.emails.length > 0);
          const sortedEmailGroup = filteredEmailGroups.sort((a, b) => a?.description.localeCompare(b?.description));
          setEmailGroups(sortedEmailGroup);
          const contactGroup = fetchedEmailGroups.find(groupWithContact => groupWithContact?.contact?.id === props.weightNote?.receiver?.id);
          if (contactGroup) {
            setSelectedEmailGroups([contactGroup.description]);
          }

        }
      } catch (error) {
        logError(error);
      }
    };

    fetchData();

  }, [props.open, props.user, props.weightNote?.receiver?.id, openEmailControl]);

  useEffect(() => {
    setSubject(
      (props.weightNote.weightNoteDate ? dayjs(props.weightNote.weightNoteDate).format('DD.MM.YYYY') : '') +
      ' - ' +
      props.landing.ship?.name +
      ' - ' +
      props.landing.harbour?.harbourName +
      (props.weightNote?.noteNumber ? ' - ' + props.weightNote.noteNumber : '')
    );

    setContent(
      (props.weightNote.receiver?.name ? props.weightNote.receiver?.name + ' - ' : '') +
      (props.weightNote.receiver?.ssn ? props.weightNote.receiver?.ssn : '')
    );
  }, [props.weightNote, props.landing]);

  const handleClose = () => {
    setSelectedEmailGroups([]);
    setSelectedEmails([]);
    props.toggleOpen();
  };

  const handleSubjectChange = (subject: string) => {
    setSubject(subject);
  };

  const handleContentChange = (content: string) => {
    setContent(content);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      // Combine emails from selected groups and selected individual emails
      const selectedEmailObjects: Email[] = [];

      // Iterate through selected email groups
      selectedEmailGroups.forEach(groupDescription => {
        const group = emailGroups.find(group => group.description === groupDescription);
        if (group) {
          selectedEmailObjects.push(...group.emails);
        }
      });

      // Add selected individual emails, ensure uniqueness
      selectedEmails.forEach(email => {
        if (!selectedEmailObjects.find(e => e.email === email)) {
          selectedEmailObjects.push({ email: email });
        }
      });

      // Extract unique email addresses from selectedEmailObjects
      const uniqueEmailAddresses = Array.from(new Set(selectedEmailObjects.map(email => email.email)));

      const emailBody: EmailSend = {
        weightNoteId: props.weightNote.id,
        emailAddress: uniqueEmailAddresses,
        subject: subject,
        body: content
      };
      await sendEmailWeightNote(emailBody);
      props.showSnackbar('Vigtarnóta send', 'success');

      // Update weightNote with emailSent = true, to show correct icon.
      props.weightNote.emailSent = true;

      handleClose();
      setLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setLoading(false);
      if (error.response && error.response.data && typeof error.response.data !== 'object') {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');
      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp', 'error');
      }
    }
  };

  const toggleEmailControlModal = () => {
    setOpenEmailControl(!openEmailControl);
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleEmailSelectionChange = (event: SelectChangeEvent<typeof selectedEmails>) => {
    const {
      target: { value },
    } = event;
    setSelectedEmails(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleEmailGroupSelectionChange = (event: SelectChangeEvent<typeof selectedEmailGroups>) => {
    const {
      target: { value },
    } = event;
    setSelectedEmailGroups(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      PaperComponent={props.isMobileView ? MobilePaperComponent : PaperComponent}
      aria-labelledby="draggable-dialog-title"
      maxWidth="sm"
    >
      <EmailModal
        open={openEmailControl}
        toggleOpen={toggleEmailControlModal}
        showSnackbar={props.showSnackbar}
        user={props.user}
      />
      <div id="draggable-dialog-title"
        style={{ width: '100%' }}
      >
        <Typography style={{ fontWeight: 'bold', lineHeight: '1.5em', fontSize: '1.5em', cursor: 'move' }}>
          Senda vigtarnótu
        </Typography>
      </div>
      <div style={{ position: 'absolute', top: 0, right: 0 }}>
        <Tooltip title="Stjórna netföngum">
          <IconButton color='primary' size='large' onClick={toggleEmailControlModal}>
            <QueueIcon />
          </IconButton>
        </Tooltip>
        <IconButton onClick={handleClose}>
          <CancelIcon fontSize='large' />
        </IconButton>
      </div>
      <div>
        <FormControl sx={{ width: '100%' }}>
          <InputLabel id="select-multiple-chip-label"><span style={{ display: 'flex', alignItems: 'center', gap: '0.2em' }}> <PersonIcon /> Veldu viðtakendur</span></InputLabel>
          <Select
            labelId="select-multiple-chip-label"
            id="multiple-chip"
            multiple
            disabled={loading}
            value={selectedEmails}
            onChange={handleEmailSelectionChange}
            input={<OutlinedInput id="select-multiple-chip" label={<span style={{ display: 'flex', alignItems: 'center', gap: '0.2em' }}> <PersonIcon /> Veldu viðtakendur</span>} />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {emails.map((email) => (
              <MenuItem
                key={email.email}
                value={email.email}
              >
                <Typography fontWeight={selectedEmails.includes(email.email) ? 'bold' : 'regular'}>
                  {email.email}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div>
        <FormControl sx={{ width: '100%' }}>
          <InputLabel id="select-multiple-chip-label">
            <span style={{ display: 'flex', alignItems: 'center', gap: '0.2em' }}>
              <GroupsIcon /> Veldu hóp
            </span>
          </InputLabel>
          <Select
            labelId="select-multiple-chip-label"
            id="multiple-chip"
            multiple
            disabled={loading}
            value={selectedEmailGroups}
            onChange={handleEmailGroupSelectionChange}
            input={<OutlinedInput id="select-multiple-chip" label={<span style={{ display: 'flex', alignItems: 'center', gap: '0.2em' }}> <GroupsIcon /> Veldu hóp</span>} />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => {
                  const group = emailGroups.find(group => group.description === value);
                  const emailList = group?.emails.map(email => email.email).join('\n');
                  const contactInfo = group?.contact
                    ? `${group.contact.name}`
                    : '';

                  return (
                    <Chip
                      key={value}
                      sx={{
                        height: 'auto',
                        '& .MuiChip-label': {
                          display: 'block',
                          whiteSpace: 'pre-line',
                        },
                        minWidth: 0,
                        maxWidth: '100%'
                      }}
                      label={
                        <Box>
                          <Typography sx={{ textAlign: 'center' }}>
                            {group?.description}
                          </Typography>
                          <Divider sx={{ marginY: 1 }} />
                          {group?.contact && (
                            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                              <PersonIcon sx={{ marginRight: 1 }} />
                              <Typography>
                                {contactInfo}
                              </Typography>
                            </Box>
                          )}
                          <Typography marginBottom={"1em"}>
                            {emailList}
                          </Typography>
                        </Box>
                      }
                    />
                  );
                })}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {emailGroups.map((email) => (
              <MenuItem key={email.description} value={email.description}>
                <Typography component='span' fontWeight={selectedEmailGroups.includes(email.description) ? 'bold' : 'regular'}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignContent: 'center', gap: '0.2em' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1em', alignItems: 'center' }}>
                      <DescriptionIcon />
                      {email.description}
                    </Box>
                    {email.contact && (
                      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1em', alignItems: 'center' }}>
                        <PersonIcon />
                        {email.contact.name}
                      </Box>
                    )}
                  </Box>
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <TextField
        label="Efni"
        value={subject}
        onChange={(event) => handleSubjectChange(event.target.value)}
      />
      <TextField
        label="Meginmál"
        multiline
        rows={4}
        value={content}
        onChange={(event) => handleContentChange(event.target.value)}
      />
      <LoadingButton
        ref={submitButtonRef}
        size="small"
        onClick={handleSubmit}
        loading={loading}
        disabled={loading || (selectedEmails.length === 0 && selectedEmailGroups.length === 0) || subject === '' || content === ''}
        variant="contained"
      >
        <span>Senda vigtarnótu</span>
      </LoadingButton>
    </Dialog >
  );
}

export default SendWeightNoteModal;