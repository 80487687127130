import { FC, useState, useEffect, useRef } from "react";
import { Avatar, Dialog, DialogTitle, IconButton, InputAdornment, TextField, useMediaQuery, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import User from "../../../../Models/UserModels/User";
import PostCatchBody from "../../../../Models/CatchRegistrationModels/PostCatchBody";
import WeightNote from "../../../../Models/WeightNoteModels/WeightNote";
import FishingStock from "../../../../Models/CatchRegistrationModels/FishingStock";
import FishingArea from "../../../../Models/CatchRegistrationModels/FishingArea";
import CatchCombination from "../../../../Models/CatchRegistrationModels/CatchCombination";
import Destiny from "../../../../Models/CatchRegistrationModels/Destiny";
import Condition from "../../../../Models/CatchRegistrationModels/Condition";
import StorageMethod from "../../../../Models/CatchRegistrationModels/StorageMethod";
import CatchRegistrationCatch from "../../../Home/Components/CatchRegistrationModal/CatchRegistrationCatch/CatchRegistrationCatch";
import CatchRegistration from "../../../../Models/CatchRegistrationModels/CatchRegistration";
import ScaleIcon from '@mui/icons-material/Scale';
import LineWeightIcon from '@mui/icons-material/LineWeight';
import PercentIcon from '@mui/icons-material/Percent';
import CommentIcon from '@mui/icons-material/Comment';
import { AddWeight, editWeight } from "../../../../Services/EndurvigtunAPIService";
import { patchWeight, postWeight } from "../../../../Services/LondunarkerfiAPIService";
import Landing from "../../../../Models/LandingModels/Landing";
import { MobilePaperComponent, PaperComponent } from "../../../../SharedComponents/Paper/CustomPaper";
import CatchRegistrationDefaults from "../../../../Constants/CatchRegistrationDefaults";
import { logError } from "../../../../Helpers/LogError";

interface ReWeightingModalProps {
  user: User;
  open: boolean;
  selectedWeightNote: WeightNote;
  selectedWeightItem: CatchRegistration | undefined;
  fihsingStocks: FishingStock[];
  fishingArea: FishingArea[];
  storageMethods: StorageMethod[];
  destinies: Destiny[];
  conditions: Condition[];
  catchCombinations: CatchCombination[];
  toggleOpen: () => void;
  showSnackbar: (message: string, severity: string) => void;
  resetWeightItem: () => void;
  refetchLandings?: (pageNumber: number, refetching: boolean) => void;
  fetchWeightNotes?: (landing: Landing, weightNoteId: number) => Promise<WeightNote[]>;
  selectedLanding?: Landing;
}

/** 
 * Functional component for Reweighting modal.
 * @param {ReWeightingModalProps} props 
 * @returns {JSX} renders the Reweighting modal component
 * 
 * Responsible for rendering the Reweighting modal component.
*/

const ReWeightingModal: FC<ReWeightingModalProps> = (props: ReWeightingModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<PostCatchBody>(Object);
  const [currentTotalWeight, setCurrentTotalWeight] = useState<number>(0);
  const [unGuttedQuantity, setUnGuttedQuantity] = useState<number>(0);
  const [quantity, setQuantity] = useState<number>(0);
  const [icePercentage, setIcePercentage] = useState<number>(0);
  const [declaredIcePercentage, setDeclaredIcePercentage] = useState<number>(0);
  const [comment, setComment] = useState<string>('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const MAXIMUM_WEIGHT = 9999999;

  useEffect(() => {
    if (props.selectedWeightItem) {
      setCurrentTotalWeight(props.selectedWeightItem.quantity);
      setQuantity(props.selectedWeightItem.quantity);
      setUnGuttedQuantity(props.selectedWeightItem.unGuttedQuantity);
      setIcePercentage(props.selectedWeightItem.icePercentage);
      setDeclaredIcePercentage(props.selectedWeightItem.declaredIcePercentage);
      setComment(props.selectedWeightItem.userComment);
      handleChildFormChange(
        {
          unGuttedQuantity: props.selectedWeightItem.unGuttedQuantity,
          userComment: props.selectedWeightItem.userComment,
          icePercentage: props.selectedWeightItem.icePercentage,
          declaredIcePercentage: props.selectedWeightItem.declaredIcePercentage,
          weight: props.selectedWeightItem.quantity,
          fishingAreaId: props.selectedWeightItem.fishingArea.id,
          storageMethodId: props.selectedWeightItem.storageMethod.id,
          destinyId: props.selectedWeightItem.destiny.id,
          fishStockId: props.selectedWeightItem.fishingStock.id,
          conditionId: props.selectedWeightItem.condition.id,
          isUnderKilogram: props.selectedWeightItem.underKilogram
        }
      );
    } else {
      setFormData({} as CatchRegistration);
      setCurrentTotalWeight(0);
      setQuantity(0);
      setUnGuttedQuantity(0);
      setIcePercentage(0);
      setDeclaredIcePercentage(0);
      setComment('');
    }
  }, [props.selectedWeightItem]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        if (submitButtonRef.current) {
          submitButtonRef.current.click();
        }
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value?.length > 100) return;
    setComment(e.target.value);
    handleChildFormChange({ userComment: e.target.value });
  };

  const handleDeclaredIcePercentageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(e.target.value) < 0 || Number(e.target.value) > 100) return;
    setDeclaredIcePercentage(Number(e.target.value));
    handleChildFormChange({ declaredIcePercentage: Number(e.target.value) });
  };

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(e.target.value) < 0 || Number(e.target.value) > MAXIMUM_WEIGHT) return;
    setQuantity(Number(e.target.value));
    handleChildFormChange({ weight: Number(e.target.value) });
  };

  const handleClose = () => {
    setFormData({} as CatchRegistration);
    setQuantity(0);
    setUnGuttedQuantity(0);
    setIcePercentage(0);
    setDeclaredIcePercentage(0);
    setComment('');
    props.resetWeightItem();
    props.toggleOpen();
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const submittedFormData = formData;
      submittedFormData.amount = formData.weight;
      if (props.user?.isReweighingUser) {
        submittedFormData.isFromReWeighter = true;
        await AddWeight(submittedFormData, props.selectedWeightNote.id);
        if (props.refetchLandings) {
          props.refetchLandings(1, true);
        }
      } else {
        submittedFormData.isFromReWeighter = true;
        await postWeight(submittedFormData, props.selectedWeightNote.id);
        if (props.fetchWeightNotes && props.selectedLanding) {
          props.fetchWeightNotes(props.selectedLanding, props.selectedWeightNote.id);
        }
      }
      props.showSnackbar('Vigtun skráð', 'success');
      setLoading(false);
      handleClose();
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    catch (error: any) {
      setLoading(false);
      if (error.response && error.response.data && typeof error.response.data !== 'object') {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');

      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp!', 'error');
      }
    }
  };

  const handleUpdate = () => {
    if (props.selectedWeightItem) {
      editTheWeight(props.selectedWeightItem.id, formData);
    }
  };

  async function editTheWeight(weightId: number, editBody: PostCatchBody) {
    try {
      setLoading(true);
      editBody.isFromReWeighter = true;
      if (props.user?.isReweighingUser) {
        if (!editBody.declaredIcePercentage) {
          editBody.declaredIcePercentage = 0;
        }
        if (!editBody.unGuttedQuantity) {
          editBody.unGuttedQuantity = 0;
        }
        await editWeight(weightId, editBody);
        if (props.refetchLandings) {
          props.refetchLandings(1, true);
        }
      } else {
        await patchWeight(weightId, editBody);
        if (props.fetchWeightNotes && props.selectedLanding) {
          props.fetchWeightNotes(props.selectedLanding, props.selectedWeightNote.id);
        }
      }
      props.showSnackbar('Vigtun uppfærð', 'success');
      setLoading(false);
      handleClose();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setLoading(false);
      if (error.response && error.response.data && typeof error.response.data !== 'object') {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');
      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp!', 'error');
      }
    }
  }

  const handleChildFormChange = (updatedFormData: PostCatchBody) => {
    setFormData((prevForm) => ({
      ...prevForm,
      ...updatedFormData
    }));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleUnguttedChange = (e: any) => {
    if (Number(e.target.value) < 0 || Number(e.target.value) > MAXIMUM_WEIGHT) return;
    setUnGuttedQuantity(Number(e.target.value));
    handleChildFormChange({ unGuttedQuantity: Number(e.target.value) });
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      PaperComponent={isMobile || isTablet ? MobilePaperComponent : PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle
        id="draggable-dialog-title"
        style={{
          fontSize: '1.5em',
          fontWeight: 'bold',
          display: 'flex',
          justifyContent: 'space-between',
          cursor: 'move'
        }}
      >
        Vigtun
        <Avatar sx={{ bgcolor: theme.palette.mode === "dark" ? "#272727" : 'white', fontSize: 16, color: theme.palette.text.primary, border: '1px solid #cdcdcd', fontWeight: '500', height: 40, width: 150 }} variant="rounded">
          {currentTotalWeight + " kg" || "0 kg"}
        </Avatar>
        <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} onClick={handleClose}>
          <CancelIcon fontSize='large' />
        </IconButton>
      </DialogTitle>
      <CatchRegistrationCatch
        handleFormChange={handleChildFormChange}
        allowedCombinations={props.catchCombinations}
        weight={props.selectedWeightItem}
        selectedWeightNote={props.selectedWeightNote}
        fishAreas={props.fishingArea}
        storageMethods={props.storageMethods}
        destinies={props.destinies}
        fishingStocks={props.fihsingStocks}
        conditions={props.conditions}
        disabled={props.selectedWeightNote.isClosed || loading}
        containsExported={props.selectedWeightNote.weights?.some(weight => weight.destiny?.id === CatchRegistrationDefaults.DESTINY_EXPORTED_ID) ?? false}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', width: '100%', gap: '1em' }}>
        <TextField
          label="Nettómagn"
          required
          type="number"
          variant="outlined"
          value={quantity || ''}
          onChange={handleQuantityChange}
          onKeyDown={(e) => {
            if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+" || e.key === ".") {
              e.preventDefault()
            }
          }}
          disabled={props.selectedWeightNote.isClosed || loading}
          InputProps={{
            inputProps: {
              min: 0,
              max: MAXIMUM_WEIGHT
            },
            startAdornment: <InputAdornment position="start"><ScaleIcon color="primary" /></InputAdornment>,
          }}
          style={{ width: isMobile || isTablet ? '100%' : '48%' }}
        />
        <TextField
          label="Óslægt magn"
          type="number"
          variant="outlined"
          value={unGuttedQuantity || ''}
          onChange={handleUnguttedChange}
          onKeyDown={(e) => {
            if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+" || e.key === ".") {
              e.preventDefault()
            }
          }}
          disabled={props.selectedWeightNote.isClosed || loading}
          InputProps={{
            inputProps: {
              min: 0,
              max: MAXIMUM_WEIGHT
            },
            startAdornment: <InputAdornment position="start"><LineWeightIcon color="primary" /></InputAdornment>,
          }}
          style={{ width: isMobile || isTablet ? '100%' : '48%' }}
        />
        <TextField
          type="number"
          disabled={true}
          id="icePercentage"
          label="Ís (%)"
          variant="outlined"
          value={icePercentage ? icePercentage : icePercentage !== 0 ? icePercentage : ''}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PercentIcon color='primary' />
              </InputAdornment>
            )
          }}
          style={{ width: isMobile || isTablet ? '100%' : '48%' }}
        />
        <TextField
          type="number"
          disabled={loading || props.selectedWeightNote.isClosed}
          id="declaredIcePercentage"
          label="Uppgefin ís (%)"
          variant="outlined"
          value={declaredIcePercentage ? declaredIcePercentage : declaredIcePercentage !== 0 ? declaredIcePercentage : ''}
          onChange={handleDeclaredIcePercentageChange}
          InputProps={{
            inputProps: {
              min: 0,
              max: 100
            },
            startAdornment: (
              <InputAdornment position="start">
                <PercentIcon color='primary' />
              </InputAdornment>
            )
          }}
          style={{ width: isMobile || isTablet ? '100%' : '48%' }}
        />
        <TextField
          type="string"
          disabled={props.selectedWeightNote.isClosed || loading}
          id="comment"
          label="Athugasemd"
          variant="outlined"
          onChange={handleCommentChange}
          value={comment ? comment : ''}
          InputProps={{
            inputProps: {
              maxLength: 100
            },
            startAdornment: (
              <InputAdornment position="start">
                <CommentIcon color='primary' />
              </InputAdornment>
            )
          }}
          style={{ width: '100%' }}
        />
      </div>
      <div>
        {props.selectedWeightItem ? (
          <LoadingButton
            fullWidth
            ref={submitButtonRef}
            size="small"
            onClick={handleUpdate}
            loading={loading}
            disabled={
              loading ||
              props.selectedWeightNote.isClosed ||
              !formData.fishingAreaId ||
              !formData.storageMethodId ||
              !formData.destinyId ||
              !formData.fishStockId ||
              !formData.conditionId ||
              !formData.weight ||
              (props.selectedWeightItem.quantity === formData.weight &&
                props.selectedWeightItem.unGuttedQuantity === formData.unGuttedQuantity &&
                props.selectedWeightItem.icePercentage === formData.icePercentage &&
                props.selectedWeightItem.declaredIcePercentage === formData.declaredIcePercentage &&
                props.selectedWeightItem.userComment === formData.userComment &&
                props.selectedWeightItem.fishingArea.id === formData.fishingAreaId &&
                props.selectedWeightItem.storageMethod.id === formData.storageMethodId &&
                props.selectedWeightItem.destiny.id === formData.destinyId &&
                props.selectedWeightItem.fishingStock.id === formData.fishStockId &&
                props.selectedWeightItem.condition.id === formData.conditionId &&
                props.selectedWeightItem.underKilogram === formData.isUnderKilogram)
            }
            endIcon={<SaveIcon />}
            color="primary"
            variant="contained"
          >
            <span>Uppfæra</span>
          </LoadingButton>
        ) : (
          <LoadingButton
            fullWidth
            ref={submitButtonRef}
            size="small"
            onClick={handleSubmit}
            loading={loading}
            disabled={
              loading ||
              props.selectedWeightNote.isClosed ||
              !formData.fishingAreaId ||
              !formData.storageMethodId ||
              !formData.destinyId ||
              !formData.fishStockId ||
              !formData.conditionId ||
              !formData.weight
            }
            endIcon={<CheckIcon />}
            color="success"
            variant="contained"
          >
            <span>Staðfesta</span>
          </LoadingButton>
        )}
      </div>
    </Dialog>
  );
};

export default ReWeightingModal;