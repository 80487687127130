import { FC, useEffect, useState } from "react";
import { Dialog, FormControlLabel, IconButton, InputAdornment, Radio, RadioGroup, TextField, useMediaQuery, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import CommentIcon from '@mui/icons-material/Comment';
import User from "../../../../../Models/UserModels/User";
import { patchVehicle, postVehicle } from "../../../../../Services/LondunarkerfiAPIService";
import SaveIcon from '@mui/icons-material/Save';
import { TitleFont, VehicleFormModalWrapper } from "./VehicleFormModal.styled";
import Vehicle from "../../../../../Models/CatchRegistrationModels/Vehicle";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import NumbersIcon from '@mui/icons-material/Numbers';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import ScaleIcon from '@mui/icons-material/Scale';
import MonitorWeightIcon from '@mui/icons-material/MonitorWeight';
import VehicleSubmit from "../../../../../Models/VehicleModels/VehicleSubmit";
import { useConfirm } from "material-ui-confirm";
import { MobilePaperComponent, PaperComponent } from "../../../../Paper/CustomPaper";
import { logError } from "../../../../../Helpers/LogError";

interface VehicleFormModalProps {
  vehicles: Vehicle[];
  vehicle: Vehicle | null;
  refetchVehicles: () => Promise<void>;
  user: User;
  open: boolean;
  toggleOpen: () => void;
  showSnackbar: (message: string, severity: 'success' | 'error') => void;
  resetVehicle: () => void;
}

/** 
 * Functional component for VehicleFormModal
 * @param {VehicleFormModalProps} props 
 * @returns {JSX} renders the VehicleFormModal component
 * 
 * Responsible for rendering the VehicleFormModal component which is used to add or edit a vehicle address.
*/

const VehicleFormModal: FC<VehicleFormModalProps> = (props: VehicleFormModalProps) => {

  const [loading, setLoading] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');
  const [vehicleNumber, setVehicleNumber] = useState<string>('');
  const [vehicleRegistrationNumber, setVehicleRegistrationNumber] = useState<string>('');
  const [tare, setTare] = useState<number>(0);
  const [ownWeight, setOwnWeight] = useState<number>(0);
  const [vehicleTypeId, setVehicleTypeId] = useState<number>(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const MAXIMUM_TARE = 999999;
  const MAXIMUM_OWN_WEIGHT = 999999;
  const MAX_VEHICLE_NUMBER_LENGTH = 5;
  const confirm = useConfirm();

  useEffect(() => {
    if (props.vehicle) {
      setComment(props.vehicle.comment || '');
      setVehicleNumber(props.vehicle.vehicleNumber || '');
      setVehicleRegistrationNumber(props.vehicle.vehicleRegistrationNumber || '');
      setTare(props.vehicle.vehicleTare || 0);
      setOwnWeight(props.vehicle.vehicleWeight || 0);
      setVehicleTypeId(props.vehicle.vehicleTypeId || 0);
    }
    else {
      initialize();
    }
  }, [props.vehicle]);

  const initialize = () => {
    setComment('');
    setVehicleNumber('');
    setVehicleRegistrationNumber('');
    setTare(0);
    setOwnWeight(0);
    setVehicleTypeId(0);
  };

  const handleClose = () => {
    props.resetVehicle();
    props.toggleOpen();
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      // throw error if tare or ownWeight are larger than database allows number(6,0)
      if (ownWeight > 999999) {
        props.showSnackbar('Eiginþyngd er of stór!', 'error');
        setLoading(false);
        return;
      }
      if (tare > 999999) {
        props.showSnackbar('Tara er of stór!', 'error');
        setLoading(false);
        return;
      }
      if (props.vehicles.some(vehicle => vehicle.vehicleNumber === vehicleNumber || vehicle.vehicleRegistrationNumber === vehicleRegistrationNumber)) {
        props.showSnackbar('Ökutæki er nú þegar til!', 'error');
        setLoading(false);
        return;
      }
      const body: VehicleSubmit = {
        vehicleNumber: vehicleNumber,
        vehicleRegistrationNumber: vehicleRegistrationNumber,
        vehicleWeight: ownWeight,
        vehicleTare: tare,
        vehicleTypeId: vehicleTypeId,
        comment: comment
      };
      await postVehicle(body);
      initialize();
      await props.refetchVehicles();
      props.showSnackbar('Ökutæki skráð!', 'success');
      setLoading(false);
      handleClose();
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    catch (error: any) {
      setLoading(false);
      if (error.response && error.response.data && typeof error.response.data !== 'object') {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');

      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp!', 'error');
      }
    }
  };

  const handleUpdate = () => {
    if (props.vehicle) {
      const body: VehicleSubmit = {
        vehicleNumber: vehicleNumber,
        vehicleRegistrationNumber: vehicleRegistrationNumber,
        vehicleWeight: ownWeight,
        vehicleTare: tare,
        vehicleTypeId: vehicleTypeId,
        comment: comment
      };
      editVehicle(props.vehicle.id, body);
    }
  };

  async function editVehicle(vehicleId: number, editBody: VehicleSubmit) {
    try {
      setLoading(true);
      await patchVehicle(vehicleId, editBody);
      await props.refetchVehicles();
      handleClose();
      props.showSnackbar('Ökutæki uppfært', 'success');
      setLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setLoading(false);
      if (error.response && error.response.data && typeof error.response.data !== 'object') {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');
      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp!', 'error');
      }
    }
  }

  const handleInactivate = (vehicleId: number) => async () => {
    setLoading(true);
    confirm({
      title: 'Ertu alveg viss?',
      description: `Þetta mun óvirkja ökutæki nr: ${vehicleId}.`,
      confirmationText: 'Óvirkja',
      cancellationText: 'Hætta við',
      confirmationButtonProps: { variant: 'contained', color: 'warning' },
      cancellationButtonProps: { variant: 'outlined' },
    })
      .then(() => inactivateVehicle(vehicleId as number))
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
    setLoading(false);
  };

  const inactivateVehicle = async (vehicleId: number) => {
    try {
      const body: VehicleSubmit = {
        vehicleNumber: vehicleNumber,
        vehicleRegistrationNumber: vehicleRegistrationNumber,
        vehicleWeight: ownWeight,
        vehicleTare: tare,
        vehicleTypeId: vehicleTypeId,
        comment: comment,
        isActive: true
      };
      await patchVehicle(vehicleId, body);
      await props.refetchVehicles();
      props.showSnackbar("Ökutæki gert óvirkt!.", "success");
      handleClose();
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    catch (error: any) {
      setLoading(false);
      if (error.response && error.response.data && typeof error.response.data !== 'object') {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');
      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp!', 'error');
      }
    }
  };

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  const handleTareChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      setTare(0);
      return;
    }
    const tareValue = parseInt(event.target.value);
    if (tareValue > MAXIMUM_TARE) {
      return;
    }
    setTare(tareValue);
  };

  const handleOwnWeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      setOwnWeight(0);
      return;
    }
    if (parseInt(event.target.value) > MAXIMUM_OWN_WEIGHT) {
      return;
    }
    setOwnWeight(parseInt(event.target.value));
  };

  const handleVehicleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVehicleNumber(event.target.value.toUpperCase());
  };

  const handleVehicleRegistrationNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVehicleRegistrationNumber(event.target.value.toUpperCase());
  };

  const handleVehicleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVehicleTypeId(Number(event.target.value));
  };

  return (
    <VehicleFormModalWrapper>
      <Dialog
        open={props.open}
        onClose={handleClose}
        PaperComponent={isMobile || isTablet ? MobilePaperComponent : PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <TitleFont id="draggable-dialog-title">
          {props.vehicle ? 'Uppfæra ökutæki' : 'Bæta við ökutæki'}
          <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} onClick={handleClose}>
            <CancelIcon fontSize='large' />
          </IconButton>
        </TitleFont>
        <div style={{ width: '100%', display: 'flex', flexDirection: isMobile || isTablet ? 'column' : 'row', gap: '1em', justifyContent: 'space-between', paddingBottom: '0.5em' }}>
          <TextField
            sx={{ width: isMobile || isTablet ? '100%' : '50%' }}
            label="Fastnúmer"
            disabled={loading}
            value={vehicleNumber}
            onChange={handleVehicleNumberChange}
            required
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocalShippingIcon />
                </InputAdornment>
              ),
              inputProps: {
                maxLength: MAX_VEHICLE_NUMBER_LENGTH,
              }
            }}
          />
          <TextField
            sx={{ width: isMobile || isTablet ? '100%' : '50%' }}
            label="Bílnúmer"
            disabled={loading}
            value={vehicleRegistrationNumber}
            onChange={handleVehicleRegistrationNumberChange}
            required
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <NumbersIcon />
                </InputAdornment>
              ),
              inputProps: {
                maxLength: MAX_VEHICLE_NUMBER_LENGTH,
              }
            }}
          />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: isMobile || isTablet ? 'column' : 'row', gap: '1em', justifyContent: 'space-between', paddingBottom: '0.5em' }}>
          <TextField
            sx={{ width: isMobile || isTablet ? '100%' : '50%' }}
            label="Tara (Kg.)"
            type="number"
            disabled={loading}
            value={tare === 0 ? '' : tare}
            onChange={handleTareChange}
            variant="outlined"
            onKeyDown={(e) => {
              if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
                e.preventDefault()
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <ScaleIcon />
                </InputAdornment>
              ),
              inputProps: {
                min: 0,
                max: MAXIMUM_TARE
              },
            }}
          />
          <TextField
            sx={{ width: isMobile || isTablet ? '100%' : '50%' }}
            label="Eiginþyngd (Kg.)"
            type="number"
            disabled={loading}
            value={ownWeight === 0 ? '' : ownWeight}
            onChange={handleOwnWeightChange}
            onKeyDown={(e) => {
              if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
                e.preventDefault()
              }
            }}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MonitorWeightIcon />
                </InputAdornment>
              ),
              inputProps: {
                min: 0,
                max: MAXIMUM_OWN_WEIGHT
              },
            }}
          />
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: isMobile || isTablet ? 'column' : 'row', gap: '1em', justifyContent: 'space-between', paddingBottom: '0.5em' }}>
          <TextField
            sx={{ width: isMobile || isTablet ? '100%' : '49%' }}
            disabled={loading}
            label="Athugasemd"
            onChange={handleCommentChange}
            value={comment}
            InputProps={{
              inputProps: {
                maxLength: 100
              },
              startAdornment: <InputAdornment position="start"><CommentIcon /></InputAdornment>,
            }}
          />
          <RadioGroup
            aria-label="vehicle-type"
            name="vehicle-type"
            value={vehicleTypeId}
            onChange={handleVehicleTypeChange}
            sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <FormControlLabel value="1" control={<Radio />} label="Bíll" />
            <FormControlLabel value="2" control={<Radio />} label="Lyftari" />
          </RadioGroup>
        </div>
        <div>
          {props.vehicle ? (
            <div style={{ display: 'flex', gap: '0.5em' }}>
              <LoadingButton
                fullWidth
                size="small"
                onClick={handleInactivate(props.vehicle.id)}
                loading={loading}
                disabled={loading || (props.user?.role.id === Number(process.env.REACT_APP_ROLE_READ_ID))}
                endIcon={<DisabledByDefaultIcon />}
                color="error"
                variant="contained">
                <span>Óvirkja</span>
              </LoadingButton>
              <LoadingButton
                fullWidth
                size="small"
                onClick={handleUpdate}
                loading={loading}
                disabled={loading || !vehicleNumber || !vehicleRegistrationNumber || !vehicleTypeId || props.user?.role.id === Number(process.env.REACT_APP_ROLE_READ_ID)}
                endIcon={<SaveIcon />}
                color="primary"
                variant="contained">
                <span>Uppfæra</span>
              </LoadingButton>
            </div>
          ) : (
            <LoadingButton
              fullWidth
              size="small"
              onClick={handleSubmit}
              loading={loading}
              disabled={loading || !vehicleNumber || !vehicleRegistrationNumber || !vehicleTypeId || props.user?.role.id === Number(process.env.REACT_APP_ROLE_READ_ID)}
              endIcon={<CheckIcon />}
              color="success"
              variant="contained">
              <span>Staðfesta</span>
            </LoadingButton>)}
        </div>
      </Dialog>
    </VehicleFormModalWrapper>
  );
};

export default VehicleFormModal;