import { DataGrid, GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { FC, useMemo, useState } from 'react';
import WeightNote from '../../../../Models/WeightNoteModels/WeightNote';
import CatchRegistration from '../../../../Models/CatchRegistrationModels/CatchRegistration';
import ReWeightingModal from '../ReWeightingModal/ReweightingModal';
import CatchCombination from '../../../../Models/CatchRegistrationModels/CatchCombination';
import FishingArea from '../../../../Models/CatchRegistrationModels/FishingArea';
import StorageMethod from '../../../../Models/CatchRegistrationModels/StorageMethod';
import Destiny from '../../../../Models/CatchRegistrationModels/Destiny';
import FishingStock from '../../../../Models/CatchRegistrationModels/FishingStock';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import SetMealIcon from '@mui/icons-material/SetMeal';
import SignLanguageIcon from '@mui/icons-material/SignLanguage';
import PercentIcon from '@mui/icons-material/Percent';
import Condition from '../../../../Models/CatchRegistrationModels/Condition';
import { Avatar, Button, CardActions, Divider, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DeleteWeight } from '../../../../Services/EndurvigtunAPIService';
import { useConfirm } from 'material-ui-confirm';
import { Card, CardLabel } from '../ReWeightingList/ReWeightingList.styled';
import User from '../../../../Models/UserModels/User';
import { logError } from '../../../../Helpers/LogError';

interface ReWeightingWeightsProps {
  user: User;
  refetchLanding: (pageNumber: number, refetching: boolean) => void;
  selectedWeightNote: WeightNote;
  loading: boolean;
  showSnackbar: (message: string, severity: string) => void;
  catchCombinations: CatchCombination[];
  fishingAreas: FishingArea[];
  storageMethods: StorageMethod[];
  destinies: Destiny[];
  fishingStocks: FishingStock[];
  conditions: Condition[];
}

const ReWeightingWeights: FC<ReWeightingWeightsProps> = (props) => {
  const [reWeightingModalOpen, setReWeightingModalOpen] = useState(false);
  const [selectedWeightItem, setSelectedWeightItem] = useState<CatchRegistration | undefined>();
  const confirm = useConfirm();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const desktopView = !isMobile && !isTablet;

  const toggleModalOpen = () => {
    setReWeightingModalOpen(!reWeightingModalOpen);
  };

  const handleWeightItemClick = (weight: CatchRegistration) => {
    setSelectedWeightItem(weight);
    toggleModalOpen();
  };

  const handleNew = () => {
    setSelectedWeightItem(undefined);
    toggleModalOpen();
  };

  const handleDelete = async (id: number) => {
    try {
      confirm({
        title: 'Ertu alveg viss?',
        description: `Þetta mun eyða vigtun nr: ${id}.`,
        confirmationText: 'Eyða',
        cancellationText: 'Hætta við',
        confirmationButtonProps: { variant: 'contained', color: 'error' },
        cancellationButtonProps: { variant: 'outlined' },
      })
        .then(async () => {
          await DeleteWeight(id);
          props.showSnackbar('Vigtun eytt', 'success');
          props.refetchLanding(1, true);
        })
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .catch(() => { });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.response && error.response.data && typeof error.response.data !== 'object') {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');
      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp!', 'error');
      }
    }
  };

  const resetWeightItem = () => {
    setSelectedWeightItem(undefined);
  };

  const weightColumns = useMemo<GridColDef[]>(() => [
    { field: 'weight', headerName: 'Hafnarvog', type: 'number', headerAlign: 'left', align: 'left', flex: 1 },
    { field: 'quantity', headerName: 'Magn', type: 'number', headerAlign: 'left', align: 'left', flex: 1 },
    { field: 'condition', headerName: 'Ástand', valueGetter: (params) => params.row?.condition.name, headerAlign: 'left', align: 'left', flex: 1 },
    { field: 'fishingStock', headerName: 'Veiðistofn', valueGetter: (params) => params.row?.fishingStock.name, headerAlign: 'left', align: 'left', flex: 1 },
    { field: 'fishingArea', headerName: 'Veiðisvæði', valueGetter: (params) => params.row?.fishingArea.name, headerAlign: 'left', align: 'left', flex: 1 },
    { field: 'storageMethod', headerName: 'Geymslu aðferð', valueGetter: (params) => params.row?.storageMethod.name, headerAlign: 'left', align: 'left', flex: 1 },
    { field: 'destiny', headerName: 'Afdrif', valueGetter: (params) => params.row?.destiny.name, headerAlign: 'left', align: 'left', flex: 1 },
    { field: 'icePercentage', headerName: 'Ísprósenta (%)', type: 'number', headerAlign: 'left', align: 'left', flex: 1 },
    { field: 'iceAmount', headerName: 'Ísmagn', type: 'number', headerAlign: 'left', align: 'left', flex: 1 },
    { field: 'isUnderKilogram', headerName: 'Undirmál', type: 'boolean', headerAlign: 'left', align: 'left', flex: 1 },
    {
      field: 'actions',
      type: 'actions',
      width: 1,
      getActions: (params) => {
        if (props.selectedWeightNote.isClosed) {
          return [
            <GridActionsCellItem
              icon={<SearchIcon />}
              label="preview"
              color="primary"
              onClick={toggleModalOpen}
            />
          ];
        } else {
          return [
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              color="error"
              onClick={() => handleDelete(Number(params.id))}
            />
          ];
        }
      },
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [props.selectedWeightNote.isClosed]
  );

  const weightsAsCards = props.selectedWeightNote.weights?.map((weight) => {
    return (
      <Card
        key={weight.id}
        style={{ marginBottom: '1em', cursor: 'pointer', height: 'auto', backgroundColor: theme.palette.mode === 'dark' ? "#272727" : undefined }}
        onClick={() => handleWeightItemClick(weight)}
      >
        <Stack direction={'row'} justifyContent="space-between" alignItems="center" width={'100%'}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '1em', flexWrap: 'wrap' }}>
            <Typography variant="h5" sx={{ whiteSpace: 'nowrap' }}>
              <Stack direction="row" alignItems="center" gap={1}>
                <SetMealIcon color="primary" />
                {weight.fishingStock?.name}
              </Stack>
            </Typography>
            <Typography variant="h5" sx={{ whiteSpace: 'nowrap' }}>
              <Stack direction="row" alignItems="center" gap={1}>
                <SignLanguageIcon color="primary" />
                {weight.condition?.name}
              </Stack>
            </Typography>
          </div>
          <Avatar sx={{ width: '4em', height: '4em' }}>
            {weight.quantity + " kg."}
          </Avatar>
        </Stack>

        <Divider sx={{ marginTop: '1em', marginBottom: '1em' }} />

        <Stack direction={'row'} width={'100%'} sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <CardLabel>
            <LocationOnIcon color="primary" />
            {weight.fishingArea?.name}
          </CardLabel>

          <CardLabel>
            <AcUnitIcon color="primary" />
            {weight.storageMethod?.name}
          </CardLabel>

          <CardLabel>
            <ImportExportIcon color="primary" />
            {weight.destiny?.name}
          </CardLabel>
        </Stack>

        {weight.icePercentage !== 0 && (
          <Stack direction={'row'} width={'100%'} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Stack direction={'row'}>
              <PercentIcon color="primary" />
              {weight.icePercentage + '%'}
            </Stack>
            {weight.underKilogram && 'Undirmál'}
          </Stack>
        )}
        <CardActions>
          {props.selectedWeightNote.isClosed ? (
            <Button
              variant="contained"
              endIcon={<SearchIcon />}
              color="primary"
              onClick={toggleModalOpen}
              sx={{ width: '100%' }}
            >
              Skoða
            </Button>
          ) : (
            <Button
              variant="contained"
              endIcon={<DeleteIcon />}
              color="error" onClick={(event) => handleDelete(Number(event.currentTarget.dataset.id))}
              sx={{ width: '100%' }}
            >
              Eyða
            </Button>
          )}
        </CardActions>
      </Card>
    );
  });

  return (
    <>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: desktopView ? 'space-between' : 'space-evenly', paddingTop: '0.5em', paddingBottom: '0.5em' }}>
        {desktopView && <Typography variant='h6' ><b>Vigtanir</b></Typography>}
        <Button
          color="primary"
          size="medium"
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleNew}
          disabled={props.selectedWeightNote.isClosed || props.loading}
          sx={{ width: desktopView ? 'auto' : '95%' }}
        >
          Bæta við vigtun
        </Button>
      </div>
      {!desktopView ? (
        <div style={{
          paddingBottom: isMobile ? '20px' : '0', 
          overflowY: 'auto',
          maxHeight: 'calc(100vh - 64px)'
        }}>
          {weightsAsCards}
        </div>
      ) : (
        <DataGrid
          rows={props.selectedWeightNote.weights || []}
          columns={weightColumns}
          onRowClick={(params: GridRowParams) => handleWeightItemClick(params.row)}
          loading={props.loading}
          autoHeight
          sx={{ height: '100%', maxHeight: '100%', width: '100%' }}
        />
      )}
      <ReWeightingModal
        user={props.user}
        open={reWeightingModalOpen}
        toggleOpen={toggleModalOpen}
        showSnackbar={props.showSnackbar}
        selectedWeightNote={props.selectedWeightNote}
        selectedWeightItem={selectedWeightItem || undefined}
        resetWeightItem={resetWeightItem}
        catchCombinations={props.catchCombinations || []}
        fishingArea={props.fishingAreas}
        storageMethods={props.storageMethods}
        destinies={props.destinies}
        fihsingStocks={props.fishingStocks}
        conditions={props.conditions}
        refetchLandings={props.refetchLanding}
      />
    </>

  );
};

export default ReWeightingWeights;