import { FC, useState } from 'react';
import { useConfirm } from "material-ui-confirm";
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import UndoIcon from '@mui/icons-material/Undo';
import ScaleIcon from '@mui/icons-material/Scale';
import ErrorIcon from '@mui/icons-material/Error';
import CheckIcon from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import CommentIcon from '@mui/icons-material/Comment';
import PhishingIcon from '@mui/icons-material/Phishing';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import VisibilityIcon from '@mui/icons-material/Visibility';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import MailIcon from '@mui/icons-material/Mail';
import { Button, Divider, Tab, Tooltip, useTheme } from '@mui/material';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import User from '../../../../Models/UserModels/User';
import Gear from '../../../../Models/WeightNoteModels/Gear';
import Landing from '../../../../Models/LandingModels/Landing';
import Permit from '../../../../Models/ReWeightingModels/Permit';
import WeightNote from '../../../../Models/WeightNoteModels/WeightNote';
import EditWeightNoteModal from '../EditWeightNoteModal/EditWeightNoteModal';
import SendWeightNoteModal from '../SendWeightNoteModal/SendWeightNoteModal';
import WeightNoteType from '../../../../Models/WeightNoteModels/WeightNoteType';
import WeightNoteSubmit from '../../../../Models/WeightNoteModels/WeightNoteSubmit';
import ConfirmCloseWeightNote from '../../../ReWeighting/Components/ConfirmCloseWeightNote/ConfirmCloseWeightNote';
import { CheckForDeviation, deleteWeightNote, getWeightNoteAsPDF, patchWeightNote } from '../../../../Services/LondunarkerfiAPIService';
import { Card, NoWeightNoteFoundWrapper, WeightNoteInfoWrapper, WeightNoteTitleFont } from './WeightNoteInfo.styled';
import { LoadingButton } from '@mui/lab';
import { noop } from 'lodash';
import WeightNoteTypes from '../../../../Constants/WeightNoteTypes';
import LandingStatuses from '../../../../Constants/LandingStatuses';
import MobilePages from '../../../../Constants/MobilePages';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BusinessIcon from '@mui/icons-material/Business';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReWeightingDeviation from '../../../../Models/ReWeightingModels/ReWeightingDeviation';
import TagIcon from '@mui/icons-material/Tag';
import { logError } from '../../../../Helpers/LogError';

interface WeightNoteInfoProps {
  selectedLanding: Landing;
  selectedWeightNote: WeightNote;
  fishingGears: Gear[];
  weightNoteTypes: WeightNoteType[];
  loading: boolean;
  fetchLandings: (landingId: number, weightNoteId?: number) => void;
  fetchCompleteLandings: (pageNumber: number, refetching: boolean, requestedLandingId: number, requestedWeightNoteId?: number) => void;
  showSnackbar: (message: string, severity: string) => void;
  permits: Permit[];
  user: User;
  CatchRegistrationDataGrid: JSX.Element;
  currentPage: string;
  setCurrentPage: (currentPage: string) => void;
  isMobileView: boolean;
}

/**
 * Functional component for WeightNoteInfo.
 * Displays the information of the selected weightNote.
 * @param props 
 * - takes in various values used for dropDown values.
 * - takes in the selected landing and the selected weightNote.
 * - takes in a function to fetch landings.
 * @returns {JSX} - Responsible for returning the weightNote info.
 * should render the following components:
 * - EditWeightNoteModal
 */
const WeightNoteInfo: FC<WeightNoteInfoProps> = (props) => {

  const [editWeightNoteModalOpen, setEditWeightNoteModalOpen] = useState(false);
  const [sendWeightNoteModalOpen, setSendWeightNoteModalOpen] = useState(false);
  const [confirmCloseWeightNoteOpen, setConfirmCloseWeightNoteOpen] = useState(false);
  const [deviation, setDeviation] = useState<ReWeightingDeviation>();
  const [pdfLoading, setPdfLoading] = useState<boolean>(false);
  const [deviationLoading, setDeviationLoading] = useState<boolean>(false);
  const [calculateSampleLoading, setCalculateSampleLoading] = useState<boolean>(false);
  const [inputToFocus, setInputToFocus] = useState<string | undefined>();

  // For mobile view three dot menu.
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = useTheme();
  const confirmDelete = useConfirm();
  const confirmComplete = useConfirm();
  const confirmUndo = useConfirm();
  const confirmUndoAndCreateNew = useConfirm();

  const toggleEditWeightNoteModalOpen = (inputToFocus?: string) => {
    setInputToFocus(inputToFocus);
    setEditWeightNoteModalOpen(!editWeightNoteModalOpen);
  }

  const toggleSendWeightNoteModalOpen = () => {
    setSendWeightNoteModalOpen(!sendWeightNoteModalOpen);
  }

  const toggleConfirmCloseWeightNoteOpen = () => {
    setConfirmCloseWeightNoteOpen(!confirmCloseWeightNoteOpen);
  }

  const handleDelete = () => {
    confirmDelete({
      title: 'Ertu alveg viss?',
      description: `Þetta mun eyða vigtarnótu nr: ${props.selectedWeightNote?.id}.`,
      confirmationText: 'Eyða',
      cancellationText: 'Hætta við',
      confirmationButtonProps: { variant: 'contained', color: 'error' },
      cancellationButtonProps: { variant: 'outlined' },
    })
      .then(() => delWeightNote())
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => { });
  }

  async function delWeightNote() {
    try {
      await deleteWeightNote(props.selectedWeightNote?.id);
      if (props.selectedLanding.landingStatus?.id === LandingStatuses.OPEN) {
        props.fetchLandings(props.selectedLanding?.id, props.selectedWeightNote?.id);
      }
      else {
        props.fetchCompleteLandings(1, true, props.selectedLanding.id, undefined);
      }
      props.showSnackbar('Vigtarnótu eytt!', 'success');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.response && error.response.data && typeof error.response.data !== 'object') {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');
      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp!', 'error');
      }
    }
  }

  const handleCompleteReWeightNote = async () => {
    setDeviationLoading(true);
    try {
      // Admin users can complete weight note without deviation check
      if (props.user?.role?.id === Number(process.env.REACT_APP_ROLE_ADMIN_ID)) {
        handleCompleteWeightNote();
        setDeviationLoading(false);
        return;
      }
      const deviationResponse = await CheckForDeviation(props.selectedWeightNote?.id);
      setDeviation(deviationResponse);
      if (deviationResponse?.fromHarbourWeightNote?.id && deviationResponse?.fromReWeighterWeightNote?.id) {
        toggleConfirmCloseWeightNoteOpen();
      } else {
        handleCompleteWeightNote();
      }
      setDeviationLoading(false);
    } catch (error) {
      setDeviationLoading(false);
      logError(error);
    }
  }

  const handleCompleteWeightNote = () => {
    confirmComplete({
      title: 'Ertu alveg viss?',
      description: `Þú ert að fara loka vigtarnótu nr: ${props.selectedWeightNote?.id}.`,
      confirmationText: 'Loka vigtarnótu',
      cancellationText: 'Hætta við',
      confirmationButtonProps: { variant: 'contained', color: 'success' },
      cancellationButtonProps: { variant: 'outlined' },
    })
      .then(() => completeWeightNote())
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => { });
  }

  async function completeWeightNote() {
    try {
      const complete: WeightNoteSubmit = {
        isClosed: true
      };
      await patchWeightNote(props.selectedWeightNote?.id, complete);
      props.showSnackbar('Vigtarnóta lokuð!', 'success');
      if (props.selectedWeightNote?.weightNoteType.id === WeightNoteTypes.FROM_REWEIGHTER || props.selectedLanding.landingStatus?.id !== LandingStatuses.OPEN) {
        props.fetchCompleteLandings(1, true, props.selectedLanding.id, props.selectedWeightNote.id);
      } else {
        props.fetchLandings(props.selectedLanding?.id, props.selectedWeightNote?.id);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.response && error.response.data && typeof error.response.data !== 'object') {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');
      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp', 'error');
      }
    }
  }

  const handleViewWeightNote = async () => {
    try {
      setPdfLoading(true);
      const file = await getWeightNoteAsPDF(props.selectedWeightNote?.id);
      const contentType = file.type || '';
      if (contentType !== 'application/pdf' || !(file instanceof Blob)) {
        throw new Error('Ekki tókst að sækja PDF skjal.');
      }
      // Create a Blob URL for the PDF
      const pdfBlob = new Blob([file], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      setPdfLoading(false);
      // Open the PDF in a new tab
      window.open(pdfUrl, '_blank');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setPdfLoading(false);
      if (error.response && error.response.data && typeof error.response.data !== 'object') {
        props.showSnackbar(error.response.data, 'error');
      }
      else {
        props.showSnackbar('Óvænt villa kom upp', 'error');
      }
    }
  };

  const handleSendWeightNote = () => {
    toggleSendWeightNoteModalOpen();
  };

  const handleCalculateSample = async () => {
    try {
      setCalculateSampleLoading(true);
      const calculateSample: WeightNoteSubmit = {
        calculateSample: true
      };
      await patchWeightNote(props.selectedWeightNote?.id, calculateSample);
      props.showSnackbar('Vigtarnóta uppreiknuð', 'success');
      props.fetchLandings(props.selectedLanding?.id, props.selectedWeightNote?.id);
      setCalculateSampleLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setCalculateSampleLoading(false);
      if (error.response && error.response.data && typeof error.response.data !== 'object') {
        props.showSnackbar(error.response.data, 'error');
      }
      else {
        props.showSnackbar('Óvænt villa kom upp', 'error');
      }
    }
  };

  const handleUndo = () => {
    confirmUndo({
      title: 'Ertu alveg viss?',
      description: `Þú ert að bakfæra vigtarnótu nr: ${props.selectedWeightNote?.id}.`,
      confirmationText: 'Bakfæra',
      cancellationText: 'Hætta við',
      confirmationButtonProps: { variant: 'contained', color: 'warning' },
      cancellationButtonProps: { variant: 'outlined' },
    })
      .then(() => undoWeightNote())
      .catch(noop);
  };

  const handleUndoAndCreateNew = () => {
    confirmUndoAndCreateNew({
      title: 'Ertu alveg viss?',
      description: `Þú ert að breyta vigtarnótu nr: ${props.selectedWeightNote?.id}`,
      confirmationText: 'Breyta',
      cancellationText: 'Hætta við',
      confirmationButtonProps: { variant: 'contained', color: 'warning' },
      cancellationButtonProps: { variant: 'outlined' },
    })
      .then(() => undoAndCreateNew())
      .catch(noop);
  };

  async function undoAndCreateNew() {
    try {
      const undo: WeightNoteSubmit = {
        undoAndCreateNew: true
      };
      const newWeightNoteResponse = await patchWeightNote(props.selectedWeightNote?.id, undo);
      if (props.selectedLanding.landingStatus?.id === LandingStatuses.OPEN) {
        props.fetchLandings(props.selectedLanding?.id, newWeightNoteResponse ? newWeightNoteResponse.data : undefined);
      } else {
        props.fetchCompleteLandings(1, true, props.selectedLanding.id, newWeightNoteResponse ? newWeightNoteResponse.data : undefined);
      }
      props.showSnackbar('Vigtarnóta bakfærð og ný stofnuð!', 'success');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.response && error.response.data && typeof error.response.data !== 'object') {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');
      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp', 'error');
      }
    }
  }

  async function undoWeightNote() {
    try {
      const undo: WeightNoteSubmit = {
        undo: true
      };
      await patchWeightNote(props.selectedWeightNote?.id, undo);
      if (props.selectedLanding.landingStatus?.id === LandingStatuses.OPEN) {
        props.fetchLandings(props.selectedLanding?.id);
      } else {
        props.fetchCompleteLandings(1, true, props.selectedLanding.id);
      }
      props.showSnackbar('Vigtarnóta bakfærð!', 'success');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.response && error.response.data && typeof error.response.data !== 'object') {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');
      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp', 'error');
      }
    }
  }

  function getStatusChip(status: 'opin' | 'lokuð' | 'endurvigtun' | 'frávik') {
    if (status === 'lokuð') {
      return <Tooltip title="Staða vigtarnótu" arrow enterDelay={200}>
        <Button
          disableRipple
          component="span"
          variant="contained"
          size="medium"
          startIcon={<CheckIcon />}
          disableElevation
          color="success"
          sx={{ textTransform: 'none', borderRadius: 1, cursor: 'default', minWidth: 'fit-content' }}
        >
          Lokuð vigtarnóta
        </Button>
      </Tooltip>
    }
    if (status === 'endurvigtun') {
      return <Tooltip title="Staða vigtarnótu" arrow enterDelay={200}>
        <Button
          disableRipple
          component="span"
          variant="contained"
          size="medium"
          startIcon={<ErrorIcon />}
          disableElevation
          color="error"
          sx={{ textTransform: 'none', borderRadius: 1, cursor: 'default', minWidth: 'fit-content' }}
        >
          Vantar endurvigtun
        </Button>
      </Tooltip>
    }
    if (status === 'frávik') {
      return <Tooltip title="Staða vigtarnótu" arrow enterDelay={200}>
        <Button
          disableRipple
          component="span"
          variant="contained"
          size="medium"
          startIcon={<NewReleasesIcon />}
          disableElevation
          color="error"
          sx={{ textTransform: 'none', borderRadius: 1, cursor: 'default', minWidth: 'fit-content' }}
        >
          Frávik í vigtarnótu
        </Button>
      </Tooltip>
    }
    return <Tooltip title="Staða vigtarnótu" arrow enterDelay={200}>
      <Button
        disableRipple
        component="span"
        variant="contained"
        size="medium"
        startIcon={<NewReleasesIcon />}
        disableElevation
        sx={{ textTransform: 'none', borderRadius: 1, cursor: 'default', minWidth: props.isMobileView ? 'fit-content' : '' }}
      >
        Opin vigtarnóta
      </Button>
    </Tooltip>
  }

  function getPermitChip(permitId: number) {
    const permit = props.permits.find((permit) => permit.permitId === permitId);
    if (permit) {
      return <Tooltip title={<span style={{ whiteSpace: 'pre-line', fontSize: '1em' }}>{permit.homeAdress + ' ' + permit.postalCode + ' ' + permit.place + '\n (' + permit.permitId + ' ' + permit.typeOfPermitName + ' - ' + permit.contactType + ' )'}</span>} arrow enterDelay={200}>
        <Button
          component="span"
          variant="outlined"
          size={props.isMobileView ? 'large' : 'medium'}
          startIcon={<WorkspacePremiumIcon />}
          disableElevation
          color="primary"
          sx={{
            textTransform: 'none', borderRadius: 10,
            cursor: props.selectedWeightNote.isClosed ? 'default' : 'pointer',
            color: theme.palette.mode === 'dark' ? 'white' : 'black',
            borderColor: 'lightgray',
            minWidth: 'fit-content',
            '&:hover': {
              color: theme.palette.mode === 'dark' ? 'white' : 'black',
              borderColor: 'darkgray',
              backgroundColor: '#f8f8f8',
            }
          }}
          onClick={props.selectedWeightNote.isClosed ? undefined : () => toggleEditWeightNoteModalOpen('permit')}
        >
          Vigtunarleyfi
        </Button>
      </Tooltip>
    }
  }

  const shouldRenderCloseWeightNoteButton = () => {
    if (props.selectedWeightNote?.isClosed) return false;
    if (props.user?.role?.id === Number(process.env.REACT_APP_ROLE_READ_ID)) return false;
    if (props.selectedWeightNote?.weights?.length === 0) return false;
    if (props.selectedWeightNote?.weightNoteType?.id === WeightNoteTypes.PRODUCT_SAMPLE_ID || props.selectedWeightNote?.weightNoteType?.id === WeightNoteTypes.PRODUCT_ID) {
      if (props.selectedWeightNote?.weights?.every(weight => weight.deductions?.length === 0)) {
        return false;
      }
    }
    if (props.selectedWeightNote?.weightNoteType?.id === WeightNoteTypes.PRODUCT_ID) {
      if (props.selectedWeightNote?.weights?.every(weight => weight.weight === 0)) {
        return false;
      }
    }
    return true;
  }

  const shouldRenderViewAndSendButtons = () => {
    if (props.selectedWeightNote?.isClosed) return true;
    return false;
  }

  const shouldRenderRollbackButton = () => {
    // Harbour users can only rollback theese types of weight notes.
    if (props.user?.role?.id === Number(process.env.REACT_APP_ROLE_WRITE_ID)) {
      const validWeightNoteTypes = [
        WeightNoteTypes.HARBOUR_SCALE_ID,
        WeightNoteTypes.TO_REWEIGHING,
        WeightNoteTypes.TO_HOME_REWEIGHING,
      ];
      if (!validWeightNoteTypes.includes(props.selectedWeightNote?.weightNoteType?.id)) {
        return false;
      }
    }
    if (props.selectedWeightNote?.isClosed &&
      props.user?.role?.id !== Number(process.env.REACT_APP_ROLE_READ_ID) &&
      !props.selectedWeightNote?.undoWeightNoteId &&
      !props.selectedWeightNote?.isCredit) return true;
    return false;
  }

  const canDeleteWeightNote =
    !props.selectedWeightNote?.isClosed &&
    !props.selectedLanding?.weightNotes?.some(
      (weightNote) => weightNote.referenceWeightNoteId === props.selectedWeightNote?.id
    ) &&
    props.user?.role?.id !== Number(process.env.REACT_APP_ROLE_READ_ID) &&
    props.selectedWeightNote.weightNoteType?.id !== WeightNoteTypes.FROM_REWEIGHTER;

  const weightNoteActionButtons = (
    <span style={{ display: 'flex', gap: '0.5em', justifyContent: props.isMobileView ? 'center' : 'space-between', flexDirection: props.isMobileView ? 'column' : 'row', alignItems: 'center', padding: props.isMobileView ? '0.5em' : '' }}>
      {shouldRenderViewAndSendButtons() && (
        <>
          <LoadingButton
            aria-label="view-weight-note"
            variant='outlined'
            size='small'
            color='primary'
            disabled={pdfLoading}
            loading={pdfLoading}
            onClick={handleViewWeightNote}
            sx={{ height: '2.5em', display: 'flex', alignItems: 'center', gap: '0.5em', borderRadius: 1, minWidth: props.isMobileView ? '10em' : '7em' }}
          >
            <VisibilityIcon fontSize='small' />
            <span>Skoða</span>
          </LoadingButton><Button
            aria-label="send-weight-note"
            variant='outlined'
            size='small'
            color='secondary'
            onClick={handleSendWeightNote}
            sx={{ height: '2.5em', display: 'flex', alignItems: 'center', gap: '0.5em', borderRadius: 1, minWidth: props.isMobileView ? '10em' : '7em' }}
          >
            {props.selectedWeightNote?.emailSent ? <MarkEmailReadIcon fontSize='small' /> : <MailIcon fontSize='small' />}
            Senda
          </Button>
        </>
      )}

      {props.selectedWeightNote?.isClosed &&
        props.selectedLanding.landingStatus?.id === LandingStatuses.OPEN &&
        props.selectedWeightNote?.weightNoteType?.id === WeightNoteTypes.PRODUCT_SAMPLE_ID &&
        props.user?.role?.id !== Number(process.env.REACT_APP_ROLE_READ_ID) &&
        props.selectedWeightNote?.referenceWeightNoteId === null &&
        (
          <LoadingButton
            aria-label="calculate-sample"
            variant='outlined'
            size='small'
            color='warning'
            loading={calculateSampleLoading}
            disabled={calculateSampleLoading}
            onClick={handleCalculateSample}
            sx={{ height: '2.5em', display: 'flex', alignItems: 'center', gap: '0.5em', borderRadius: 1, minWidth: props.isMobileView ? '10em' : '7em' }}
          >
            <UnarchiveIcon fontSize='small' />
            <span>Uppreikna</span>
          </LoadingButton>
        )
      }
      {shouldRenderRollbackButton() &&
        (
          <Tooltip title="Bakfæra vigtarnótu" arrow>
            <Button
              aria-label="rollback-weight-note"
              variant='outlined'
              size='small'
              color='warning'
              onClick={handleUndo}
              sx={{ height: '2.5em', display: 'flex', alignItems: 'center', gap: '0.5em', borderRadius: 1, minWidth: props.isMobileView ? '10em' : '7em' }}
              disabled={!props.selectedWeightNote?.isClosed || props.user?.role?.id === Number(process.env.REACT_APP_ROLE_READ_ID)}
            >
              <UndoIcon fontSize='small' />
              Bakfæra
            </Button>
          </Tooltip>
        )}

      {shouldRenderRollbackButton() &&
        (
          <Tooltip title="Breyta vigtarnótu" arrow>
            <Button
              aria-label="rollback-and-create-weight-note"
              variant='outlined'
              size='small'
              color='error'
              onClick={handleUndoAndCreateNew}
              sx={{ height: '2.5em', display: 'flex', alignItems: 'center', gap: '0.5em', borderRadius: 1, minWidth: props.isMobileView ? '10em' : '7em' }}
              disabled={!props.selectedWeightNote?.isClosed || props.user?.role?.id === Number(process.env.REACT_APP_ROLE_READ_ID)}
            >
              <SwapHorizIcon fontSize='small' />
              Breyta
            </Button>
          </Tooltip>
        )}

      {shouldRenderCloseWeightNoteButton() &&
        (
          <LoadingButton
            aria-label="close-weight-note"
            variant='outlined'
            size='small'
            color='success'
            onClick={props.selectedWeightNote?.weightNoteType?.id === WeightNoteTypes.FROM_REWEIGHTER ? handleCompleteReWeightNote : handleCompleteWeightNote}
            sx={{ height: '2.5em', display: 'flex', alignItems: 'center', gap: '0.5em', borderRadius: 1, minWidth: props.isMobileView ? '10em' : '7em' }}
            disabled={props.selectedWeightNote?.isClosed || props.user?.role?.id === Number(process.env.REACT_APP_ROLE_READ_ID) || deviationLoading}
            loading={deviationLoading}
          >
            <LockIcon fontSize='small' />
            <span>Loka</span>
          </LoadingButton>
        )}

      {canDeleteWeightNote &&
        (
          <Button
            aria-label="delete-weight-note"
            variant='outlined'
            size='small'
            color='error'
            onClick={handleDelete}
            sx={{ height: '2.5em', display: 'flex', alignItems: 'center', gap: '0.5em', borderRadius: 1, minWidth: props.isMobileView ? '10em' : '7em' }}
          >
            <DeleteForeverIcon fontSize='small' />
            Eyða
          </Button>
        )}
    </span>);

  return (
    <WeightNoteInfoWrapper
      style={{
        height: props.isMobileView ? '100%' : '',
        padding: props.isMobileView ? '0' : '',
      }}>
      {((props.isMobileView && props.currentPage === MobilePages.WEIGHT_NOTE_DETAILS) || !props.isMobileView) &&
        <Card style={{
          width: '100%',
          height: '100%',
          cursor: 'unset',
          display: 'flex',
          backgroundColor: theme.palette.mode === "dark" ? "#272727" : undefined,
          padding: props.isMobileView ? '0' : '',
          borderRadius: props.isMobileView ? '0' : '',
        }}>
          {props.selectedWeightNote?.id === undefined && (
            <NoWeightNoteFoundWrapper>
              <SearchIcon sx={{ opacity: 0.5 }} />
              {'Engin vigtarnóta valin'}
            </NoWeightNoteFoundWrapper>)
          }

          {(props.selectedLanding && props.selectedWeightNote?.id) && (
            <span style={{
              display: 'flex',
              flex: props.isMobileView ? '' : '1 1 auto',
              flexDirection: 'column',
              gap: '0.5em',
              paddingRight: props.isMobileView ? '0.5em' : '1em',
              paddingLeft: props.isMobileView ? '0.5em' : '1em',
              paddingTop: props.isMobileView ? '0.5em' : '1em'
            }}>
              <WeightNoteTitleFont>
                <span style={{ display: 'flex', flexDirection: 'column', gap: '0em', width: '100%' }}>
                  <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>

                    <span style={{
                      display: 'flex', flexDirection: props.isMobileView ? 'row' : 'row', gap: '0.25em', alignItems: 'center', flexWrap: 'wrap',
                      cursor: props.selectedWeightNote.isClosed ? 'default' : 'pointer'
                    }}>

                      {props.isMobileView &&
                        <span style={{ display: 'flex', flexDirection: 'row', gap: '0.25em', alignItems: 'center' }}
                          onClick={props.selectedWeightNote.isClosed ? undefined : () => toggleEditWeightNoteModalOpen('receiver')}
                        >
                          <BusinessIcon /> {props.selectedWeightNote?.receiver?.name}
                        </span>
                      }

                      {!props.isMobileView &&
                        <>
                          <Tooltip title={<span style={{ whiteSpace: 'pre-line', fontSize: '1.1em' }}>{'Kaupandi\n\n' + props.selectedWeightNote.buyer.name + "\n" + props.selectedWeightNote.buyer.ssn + "\n" + props.selectedWeightNote.buyer.homeAddress + "\n" + props.selectedWeightNote.buyer.postalCodeName}</span>} arrow enterDelay={200}>
                            <span style={{ display: 'flex', flexDirection: 'row', gap: '0.25em', alignItems: 'center' }}
                              onClick={props.selectedWeightNote.isClosed ? undefined : () => toggleEditWeightNoteModalOpen('buyer')}
                            >
                              <AttachMoneyIcon /> {props.selectedWeightNote?.buyer?.name}
                            </span>
                          </Tooltip>
                          {!props.isMobileView && <>&nbsp;/&nbsp;</>}
                          <Tooltip title={<span style={{ whiteSpace: 'pre-line', fontSize: '1.1em' }}>{'Viðtakandi\n\n' + props.selectedWeightNote.receiver.name + "\n" + props.selectedWeightNote.receiver.ssn + "\n" + props.selectedWeightNote.receiver.homeAddress + "\n" + props.selectedWeightNote.receiver.postalCodeName}</span>} arrow enterDelay={200}>
                            <span style={{ display: 'flex', flexDirection: 'row', gap: '0.5em', alignItems: 'center' }}
                              onClick={props.selectedWeightNote.isClosed ? undefined : () => toggleEditWeightNoteModalOpen('receiver')}
                            >
                              <BusinessIcon /> {props.selectedWeightNote?.receiver?.name}
                            </span>
                          </Tooltip>
                        </>
                      }
                    </span>

                    {props.isMobileView && (
                      <>
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={open ? 'long-menu' : undefined}
                          aria-expanded={open ? 'true' : undefined}
                          aria-haspopup="true"
                          onClick={handleClick}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id="long-menu"
                          MenuListProps={{
                            'aria-labelledby': 'long-button',
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          sx={{ '& .MuiMenu-list': { padding: 0 } }}
                        >
                          {weightNoteActionButtons}
                        </Menu>
                      </>
                    )
                    }

                    {/* Weight note action buttons in desktop view */}
                    {!props.isMobileView && weightNoteActionButtons}
                  </span>

                  {props.isMobileView && <Divider style={{ width: '100%', margin: '0.5em 0' }} />}

                </span>
              </WeightNoteTitleFont>

              <Tabs
                variant="scrollable"
                value={0}
                scrollButtons="auto"
                allowScrollButtonsMobile
                sx={{
                  [`& .${tabsClasses.scrollButtons}`]: {
                    '&.Mui-disabled': { opacity: 0.3 },
                  },
                  '& .MuiTabs-scroller': {
                    '& .MuiTabs-flexContainer': {
                      gap: '0.5em',
                    }
                  },
                  '& .MuiTabs-indicator': {
                    backgroundColor: theme.palette.mode === 'dark' ? 'black' : 'white',
                  },
                }}
              >
                <Tab disableRipple style={{ padding: 0, minWidth: 'fit-content' }}
                  label={props.selectedWeightNote.hasDeviation ? getStatusChip('frávik') :
                    getStatusChip(props.selectedWeightNote.isClosed ? 'lokuð' :
                      props.selectedWeightNote?.weightNoteType?.id === WeightNoteTypes.FROM_REWEIGHTER ? 'endurvigtun' :
                        'opin')} />

                <Tab style={{ padding: 0, minWidth: 'fit-content' }}
                  label={
                    <Tooltip title="Veiðarfæri" arrow enterDelay={200}>
                      <Button
                        component="span"
                        variant="outlined"
                        size={props.isMobileView ? 'large' : 'medium'}
                        startIcon={<PhishingIcon />}
                        disableElevation
                        color="primary"
                        sx={{
                          textTransform: 'none', borderRadius: 10,
                          cursor: props.selectedWeightNote.isClosed ? 'default' : 'pointer',
                          color: theme.palette.mode === 'dark' ? 'white' : 'black',
                          borderColor: 'lightgray',
                          minWidth: 'fit-content',
                          '&:hover': {
                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                            borderColor: 'darkgray',
                            backgroundColor: '#f8f8f8',
                          }
                        }}
                        onClick={props.selectedWeightNote.isClosed ? undefined : () => toggleEditWeightNoteModalOpen('fishingGear')}
                      >
                        {props.selectedWeightNote?.gear?.name}
                      </Button>
                    </Tooltip>} />

                <Tab style={{ padding: 0, minWidth: 'fit-content' }}
                  label={
                    <Tooltip title="Tegund vigtunar" arrow enterDelay={200}>
                      <Button
                        component="span"
                        variant="outlined"
                        size={props.isMobileView ? 'large' : 'medium'}
                        startIcon={<ScaleIcon />}
                        disableElevation
                        color="primary"
                        sx={{
                          textTransform: 'none', borderRadius: 10,
                          cursor: props.selectedWeightNote.isClosed ? 'default' : 'pointer',
                          color: theme.palette.mode === 'dark' ? 'white' : 'black',
                          borderColor: 'lightgray',
                          minWidth: 'fit-content',
                          '&:hover': {
                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                            borderColor: 'darkgray',
                            backgroundColor: '#f8f8f8',
                          }
                        }}
                        onClick={props.selectedWeightNote.isClosed ? undefined : () => toggleEditWeightNoteModalOpen('weightNoteType')}
                      >
                        {props.selectedWeightNote?.weightNoteType?.name}
                      </Button>
                    </Tooltip>} />

                {props.selectedWeightNote?.comment &&
                  <Tab style={{ padding: 0, minWidth: 'fit-content' }}
                    label={
                      <Tooltip title="Athugasemd" arrow enterDelay={200}>
                        <Button
                          component="span"
                          variant="outlined"
                          size={props.isMobileView ? 'large' : 'medium'}
                          startIcon={<CommentIcon />}
                          disableElevation
                          color="primary"
                          sx={{
                            textTransform: 'none', borderRadius: 10,
                            cursor: props.selectedWeightNote.isClosed ? 'default' : 'pointer',
                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                            borderColor: 'lightgray',
                            minWidth: 'fit-content',
                            '&:hover': {
                              color: theme.palette.mode === 'dark' ? 'white' : 'black',
                              borderColor: 'darkgray',
                              backgroundColor: '#f8f8f8',
                            }
                          }}
                          onClick={props.selectedWeightNote.isClosed ? undefined : () => toggleEditWeightNoteModalOpen('comment')}
                        >
                          {props.selectedWeightNote?.comment ? props.selectedWeightNote?.comment : '-'}
                        </Button>
                      </Tooltip>
                    } />
                }

                {/* Permit */}
                {props.selectedWeightNote.permitId &&
                  <Tab style={{ padding: 0, minWidth: 'fit-content' }}
                    label={
                      getPermitChip(props.selectedWeightNote.permitId ?? -1)
                    } />
                }

                {props.selectedWeightNote?.noteNumber &&
                  <Tab style={{ padding: 0, minWidth: 'fit-content' }}
                    label={
                      <Tooltip title="Nótunúmer" arrow enterDelay={200}>
                        <Button
                          component="span"
                          variant="outlined"
                          size={props.isMobileView ? 'large' : 'medium'}
                          startIcon={<TagIcon />}
                          disableElevation
                          color="primary"
                          sx={{
                            textTransform: 'none', borderRadius: 10,
                            cursor: 'default',
                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                            borderColor: 'lightgray',
                            minWidth: 'fit-content',
                            '&:hover': {
                              color: theme.palette.mode === 'dark' ? 'white' : 'black',
                              borderColor: 'darkgray',
                              backgroundColor: '#f8f8f8',
                            }
                          }}
                        >
                          {props.selectedWeightNote.noteNumber}
                        </Button>
                      </Tooltip>
                    } />
                }

                {(props.selectedWeightNote?.buyer && props.isMobileView) &&
                  <Tab style={{ padding: 0, minWidth: 'fit-content' }}
                    label={
                      <Tooltip title={<span style={{ whiteSpace: 'pre-line', fontSize: '1.1em' }}>{'Kaupandi\n\n' + props.selectedWeightNote.buyer.name + "\n" + props.selectedWeightNote.buyer.ssn + "\n" + props.selectedWeightNote.buyer.homeAddress + "\n" + props.selectedWeightNote.buyer.postalCodeName}</span>} arrow enterDelay={200}>
                        <Button
                          component="span"
                          variant="outlined"
                          size={props.isMobileView ? 'large' : 'medium'}
                          startIcon={<AttachMoneyIcon />}
                          disableElevation
                          color="primary"
                          sx={{
                            textTransform: 'none', borderRadius: 10,
                            cursor: props.selectedWeightNote.isClosed ? 'default' : 'pointer',
                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                            borderColor: 'lightgray',
                            minWidth: 'fit-content',
                            '&:hover': {
                              color: theme.palette.mode === 'dark' ? 'white' : 'black',
                              borderColor: 'darkgray',
                              backgroundColor: '#f8f8f8',
                            }
                          }}
                          onClick={props.selectedWeightNote.isClosed ? undefined : () => toggleEditWeightNoteModalOpen('buyer')}
                        >
                          {props.selectedWeightNote?.buyer?.name}
                        </Button>
                      </Tooltip>
                    } />
                }

                {(props.selectedWeightNote?.receiver && props.isMobileView) &&
                  <Tab style={{ padding: 0, minWidth: 'fit-content' }}
                    label={
                      <Tooltip title={<span style={{ whiteSpace: 'pre-line', fontSize: '1.1em' }}>{'Viðtakandi\n\n' + props.selectedWeightNote.receiver.name + "\n" + props.selectedWeightNote.receiver.ssn + "\n" + props.selectedWeightNote.receiver.homeAddress + "\n" + props.selectedWeightNote.receiver.postalCodeName}</span>} arrow enterDelay={200}>
                        <Button
                          component="span"
                          variant="outlined"
                          size={props.isMobileView ? 'large' : 'medium'}
                          startIcon={<BusinessIcon />}
                          disableElevation
                          color="primary"
                          sx={{
                            textTransform: 'none', borderRadius: 10,
                            cursor: props.selectedWeightNote.isClosed ? 'default' : 'pointer',
                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                            borderColor: 'lightgray',
                            minWidth: 'fit-content',
                            '&:hover': {
                              color: theme.palette.mode === 'dark' ? 'white' : 'black',
                              borderColor: 'darkgray',
                              backgroundColor: '#f8f8f8',
                            }
                          }}
                          onClick={props.selectedWeightNote.isClosed ? undefined : () => toggleEditWeightNoteModalOpen('receiver')}
                        >
                          {props.selectedWeightNote?.receiver?.name}
                        </Button>
                      </Tooltip>
                    } />
                }

                {!(props.selectedWeightNote.isClosed) && (
                  <Tab style={{ padding: 0, minWidth: 'fit-content' }}
                    label={
                      <Tooltip title="Breyta vigtarnótu" arrow enterDelay={200}>
                        <Button
                          component="span"
                          onClick={() => toggleEditWeightNoteModalOpen()}
                          size={props.isMobileView ? 'large' : 'medium'}
                          sx={{
                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                            borderColor: 'lightgray',
                            minWidth: 'fit-content',
                            '&:hover': {
                              color: theme.palette.mode === 'dark' ? 'white' : 'black',
                              borderColor: 'darkgray',
                              backgroundColor: '#f8f8f8',
                            }
                          }}>
                          <EditIcon fontSize='medium' />
                        </Button>
                      </Tooltip>
                    }
                  />
                )}
              </Tabs>
            </span>
          )
          }

          {props.CatchRegistrationDataGrid}

          <EditWeightNoteModal
            editWeightNoteModalOpen={editWeightNoteModalOpen}
            toggleEditWeightNoteModalOpen={toggleEditWeightNoteModalOpen}
            inputToFocus={inputToFocus}
            selectedWeightNote={props.selectedWeightNote}
            selectedLanding={props.selectedLanding}
            fetchLandings={props.fetchLandings}
            fetchCompleteLandings={props.fetchCompleteLandings}
            showSnackbar={props.showSnackbar}
            fishingGears={props.fishingGears}
            weightNoteTypes={props.weightNoteTypes}
            permits={props.permits}
            user={props.user}
          />
          <SendWeightNoteModal
            user={props.user}
            open={sendWeightNoteModalOpen}
            toggleOpen={toggleSendWeightNoteModalOpen}
            showSnackbar={props.showSnackbar}
            landing={props.selectedLanding}
            weightNote={props.selectedWeightNote}
            isMobileView={props.isMobileView}
          />
          <ConfirmCloseWeightNote
            user={props.user}
            open={confirmCloseWeightNoteOpen}
            toggleOpen={toggleConfirmCloseWeightNoteOpen}
            selectedWeightNote={props.selectedWeightNote}
            showSnackbar={props.showSnackbar}
            fetchCompleteLandings={props.fetchCompleteLandings}
            deviation={deviation ? deviation : null}
          />
        </Card >
      }
    </WeightNoteInfoWrapper >
  );
}

export default WeightNoteInfo;
