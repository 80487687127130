/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError } from 'axios';
import Landing from '../Models/LandingModels/Landing';
import LandingApiResponse from "../Models/LandingModels/LandingApiResponse";
import WeightNoteApiResponse from "../Models/WeightNoteModels/WeightNoteApiResponse";
import WeightNote from "../Models/WeightNoteModels/WeightNote";
import ReferenceDataApiResponse from "../Models/ReferenceDataModels/ReferenceDataApiResponse";
import ShipApiResponse from "../Models/ShipModels/ShipApiResponse";
import Ship from "../Models/ShipModels/Ship";
import CatchRegistration from "../Models/CatchRegistrationModels/CatchRegistration";
import CatchRegistrationApiResponse from "../Models/CatchRegistrationModels/CatchRegistrationApiResponse";
import CatchDeductionApiResponse from "../Models/CatchRegistrationModels/CatchDeductionApiResponse";
import CatchDeduction from "../Models/CatchRegistrationModels/CatchDeduction";
import Vehicle from "../Models/CatchRegistrationModels/Vehicle";
import User from "../Models/UserModels/User";
import UserHarbours from '../Models/UserModels/UserHarbours';
import UserHarboursApiResponse from '../Models/UserModels/UserHarboursApiResponse';
import ChangeHarbourBody from '../Models/UserModels/ChangeHarbourBody';
import PostCatchBody from '../Models/CatchRegistrationModels/PostCatchBody';
import PostDeductionBody from '../Models/CatchRegistrationModels/PostDeductionBody';
import Contact from '../Models/ContactModels/Contact';
import ContactApiResponse from '../Models/ContactModels/ContactApiResponse';
import LandingType from '../Models/LandingModels/LandingType';
import LandingSubmit from '../Models/LandingModels/LandingSubmit';
import WeightNoteSubmit from '../Models/WeightNoteModels/WeightNoteSubmit';
import { AuthService } from './AuthService';
import PermitApiResponse from '../Models/ReWeightingModels/PermitApiResponse';
import Permit from '../Models/ReWeightingModels/Permit';
import CatchCombination from '../Models/CatchRegistrationModels/CatchCombination';
import Gear from '../Models/WeightNoteModels/Gear';
import WeightNoteType from '../Models/WeightNoteModels/WeightNoteType';
import FishingArea from '../Models/CatchRegistrationModels/FishingArea';
import StorageMethod from '../Models/CatchRegistrationModels/StorageMethod';
import Destiny from '../Models/CatchRegistrationModels/Destiny';
import FishingStock from '../Models/CatchRegistrationModels/FishingStock';
import Condition from '../Models/CatchRegistrationModels/Condition';
import DeductionType from '../Models/CatchRegistrationModels/DeductionType';
import Email from '../Models/EmailModels/Email';
import EmailApiResponse from '../Models/EmailModels/EmailApiResponse';
import EmailSend from '../Models/EmailModels/EmailSend';
import EmailGroup from '../Models/EmailModels/EmailGroup';
import EmailGroupApiResponse from '../Models/EmailModels/EmailGroupApiResponse';
import EmailGroupPost from '../Models/EmailModels/EmailGroupInput';
import VehicleSubmit from '../Models/VehicleModels/VehicleSubmit';
import WeightReport from '../Models/ReportModels/WeightReport';
import ReportsApiResponse from '../Models/ReportModels/ReportsApiResponse';
import TraceIdsApiResponse from '../Models/LandingModels/TraceIdsApiResponse';
import TraceIds from '../Models/LandingModels/TraceIds';
import Deviation from '../Models/DeviationModels/Deviation';
import DeviationApiResponse from '../Models/DeviationModels/DeviationApiResponse';
import DeviationEdit from '../Models/DeviationModels/DeviationEdit';
import ReWeightingDeviation from '../Models/ReWeightingModels/ReWeightingDeviation';

const authService = new AuthService();
const API_BASE_URL: string | undefined = process.env.REACT_APP_API_BASE_URL;

const errorHandler = (error: unknown, forbiddenRedirect = true) => {
  const axiosError = error as AxiosError;
  if (axiosError.response?.status === 401) {
    setTimeout(() => {
      authService.logoutSilent();
    }, 2000);
    setTimeout(() => {
      authService.login();
    }, 7000);
  } else if (axiosError.response?.status === 403) {
    if (forbiddenRedirect) {
      setTimeout(() => {
        authService.logoutSilent();
      }, 2000);
      setTimeout(() => {
        authService.login();
      }, 7000);
    }
  } else {
    throw (error);
  }
}

export async function getLandings(harbourID: number | undefined, isCompleted?: boolean, pageNumber?: number, searchCondition?: string, landingStarts?: string): Promise<Landing[]> {
  try {
    if (!pageNumber) {
      pageNumber = 1;
    }
    // conditionally render searchCondition in url if it exists
    let url = `${API_BASE_URL}landings?isCompleted=${isCompleted}&pageNumber=${pageNumber}`;
    if (harbourID) {
      url = `${url}&harbourID=${harbourID}`;
    }
    if (searchCondition) {
      url = `${url}&searchCondition=${searchCondition}`;
    }
    if (landingStarts) {
      url = `${url}&landingStarts=${landingStarts}`;
    }
    const user = await authService.getUser();
    const response = await axios.get<LandingApiResponse>(url, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response.data.landings;
  } catch (error) {
    errorHandler(error);
    return [];
  }
}

export async function getLandingById(landingId: number): Promise<Landing> {
  try {
    const user = await authService.getUser();
    const response = await axios.get<Landing>(`${API_BASE_URL}landings/${landingId}`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response.data;
  } catch (error) {
    errorHandler(error);
    return {} as Landing;
  }
}

export async function getWeightNotes(landingId: number, showInvalid: boolean): Promise<WeightNote[]> {
  try {
    const user = await authService.getUser();
    // if showInvalid is true, get all weight notes, else get only valid weight notes
    let url = `${API_BASE_URL}landings/${landingId}/weight-notes`;
    if (showInvalid) {
      url = `${API_BASE_URL}landings/${landingId}/weight-notes?showInvalid=true`;
    }
    const response = await axios.get<WeightNoteApiResponse>(url, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response.data.weightNotes;
  } catch (error) {
    errorHandler(error);
    return [];
  }
}

export async function getWeightNoteById(weightNoteId: number): Promise<WeightNote> {
  try {
    const user = await authService.getUser();
    const response = await axios.get<WeightNote>(`${API_BASE_URL}weight-notes/${weightNoteId}`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response.data;
  } catch (error) {
    errorHandler(error);
    return {} as WeightNote;
  }
}

export async function getShips(searchTerm: string, showInvalid: boolean): Promise<Ship[]> {
  try {
    const user = await authService.getUser();
    const response = await axios.get<ShipApiResponse>(`${API_BASE_URL}ships?searchTerm=` + searchTerm + `&showInvalid=${showInvalid}`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response.data.ships;
  } catch (error) {
    errorHandler(error);
    return [];
  }
}

export async function getLastGearForShip(shipRegistrationNUmber: number): Promise<Gear> {
  try {
    const user = await authService.getUser();
    const response = await axios.get<Gear>(`${API_BASE_URL}ships/${shipRegistrationNUmber}/last-gear`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response.data;
  } catch (error) {
    errorHandler(error);
    return {} as Gear;
  }
}

export async function getGears(): Promise<Gear[]> {
  try {
    const user = await authService.getUser();
    const response = await axios.get<ReferenceDataApiResponse>(`${API_BASE_URL}gears`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    const sortedResponse = response.data.gears.sort((a: Gear, b: Gear) =>
      a.name.localeCompare(b.name));
    return sortedResponse;
  } catch (error) {
    errorHandler(error);
    return [];
  }
}

export async function getWeightNoteTypes(): Promise<WeightNoteType[]> {
  try {
    const user = await authService.getUser();
    const response = await axios.get<ReferenceDataApiResponse>(`${API_BASE_URL}weight-note-types`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    const sortedResponse = response.data.weightNoteTypes.sort((a: WeightNoteType, b: WeightNoteType) =>
      a.name.localeCompare(b.name));
    return sortedResponse;
  } catch (error) {
    errorHandler(error);
    return [];
  }
}

export async function getFishingAreas(): Promise<FishingArea[]> {
  try {
    const user = await authService.getUser();
    const response = await axios.get<ReferenceDataApiResponse>(`${API_BASE_URL}fishing-areas`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    const sortedResponse = response.data.fishingAreas.sort((a: FishingArea, b: FishingArea) =>
      a.name.localeCompare(b.name));
    return sortedResponse;
  } catch (error) {
    errorHandler(error);
    return [];
  }
}

export async function getStorageMethods(): Promise<StorageMethod[]> {
  try {
    const user = await authService.getUser();
    const response = await axios.get<ReferenceDataApiResponse>(`${API_BASE_URL}storage-methods`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    const sortedResponse = response.data.storageMethods.sort((a: StorageMethod, b: StorageMethod) =>
      a.name.localeCompare(b.name));
    return sortedResponse;
  } catch (error) {
    errorHandler(error);
    return [];
  }
}

export async function getDestinies(): Promise<Destiny[]> {
  try {
    const user = await authService.getUser();
    const response = await axios.get<ReferenceDataApiResponse>(`${API_BASE_URL}destinies`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    const sortedResponse = response.data.destinies.sort((a: Destiny, b: Destiny) =>
      a.name.localeCompare(b.name));
    return sortedResponse;
  } catch (error) {
    errorHandler(error);
    return [];
  }
}

export async function getFishingStocks(): Promise<FishingStock[]> {
  try {
    const user = await authService.getUser();
    const response = await axios.get<ReferenceDataApiResponse>(`${API_BASE_URL}fishing-stocks`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    const sortedResponse = response.data.fishingStocks.sort((a: FishingStock, b: FishingStock) =>
      a.name.localeCompare(b.name));
    return sortedResponse;
  } catch (error) {
    errorHandler(error);
    return [];
  }
}

export async function getConditions(): Promise<Condition[]> {
  try {
    const user = await authService.getUser();
    const response = await axios.get<ReferenceDataApiResponse>(`${API_BASE_URL}conditions`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    const sortedResponse = response.data.conditions.sort((a: Condition, b: Condition) =>
      a.name.localeCompare(b.name));
    return sortedResponse;
  } catch (error) {
    errorHandler(error);
    return [];
  }
}

export async function getDeductionTypes(): Promise<DeductionType[]> {
  try {
    const user = await authService.getUser();
    const response = await axios.get<ReferenceDataApiResponse>(`${API_BASE_URL}deduction-types`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    const sortedResponse = response.data.deductionTypes.sort((a: DeductionType, b: DeductionType) =>
      a.name.localeCompare(b.name));
    return sortedResponse;
  } catch (error) {
    errorHandler(error);
    return [];
  }
}

export async function getLandingTypes(): Promise<LandingType[]> {
  try {
    const user = await authService.getUser();
    const response = await axios.get<ReferenceDataApiResponse>(`${API_BASE_URL}landing-types`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response.data.landingTypes;
  } catch (error) {
    errorHandler(error);
    return [];
  }
}

export async function getWeightsByWeightNoteId(weightNoteId: number): Promise<CatchRegistration[]> {
  try {
    const user = await authService.getUser();
    const response = await axios.get<CatchRegistrationApiResponse>(`${API_BASE_URL}weight-notes/${weightNoteId}/weights`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response.data.weights;
  } catch (error) {
    errorHandler(error);
    return [];
  }
}

export async function getDeductionsByWeightId(weightId: number | undefined): Promise<CatchDeduction[]> {
  try {
    const user = await authService.getUser();
    const response = await axios.get<CatchDeductionApiResponse>(`${API_BASE_URL}weights/${weightId}/deductions`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response.data.deductions;
  } catch (error) {
    errorHandler(error);
    return [];
  }
}

export async function getVehiclesByHarbourId(harbourId: number | undefined): Promise<Vehicle[]> {
  try {
    const user = await authService.getUser();
    const response = await axios.get<ReferenceDataApiResponse>(`${API_BASE_URL}vehicles?harbourID=${harbourId}`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response.data.vehicles;
  } catch (error) {
    errorHandler(error);
    return [];
  }
}

export async function getWeightNotesReport(harbourID: number | undefined, searchTerm: string | undefined, pageNumber: number | undefined): Promise<WeightNote[]> {
  try {
    const user = await authService.getUser();
    let url = `${API_BASE_URL}reports/weight-notes?1=1`;
    if (harbourID) {
      url = `${url}&harbourId=${harbourID}`;
    } if (searchTerm && pageNumber) {
      url = `${url}&searchcondition=${searchTerm}&pageNumber=${pageNumber}`;
    } else if (searchTerm) {
      url = `${url}&searchcondition=${searchTerm}`;
    } else if (pageNumber) {
      url = `${url}&pageNumber=${pageNumber}`;
    }
    const response = await axios.get<WeightNoteApiResponse>(url, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response.data.weightNotes;
  } catch (error) {
    errorHandler(error);
    return [];
  }
}

export async function getCatchesReport(harbourID: number | undefined, searchTerm: string | undefined, pageNumber: number | undefined): Promise<WeightReport[]> {
  try {
    const user = await authService.getUser();
    let url = `${API_BASE_URL}reports/catch?1=1`;
    if (harbourID) {
      url = `${url}&harbourId=${harbourID}`;
    } if (searchTerm && pageNumber) {
      url = `${url}&searchcondition=${searchTerm}&pageNumber=${pageNumber}`;
    } else if (searchTerm) {
      url = `${url}&searchcondition=${searchTerm}`;
    } else if (pageNumber) {
      url = `${url}&pageNumber=${pageNumber}`;
    }
    const response = await axios.get<ReportsApiResponse>(url, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response.data.weights;
  } catch (error) {
    errorHandler(error);
    return [];
  }
}

export async function getCatchNewsReport(harbourID: number, pageNumber: number | undefined, dateFrom: string | undefined, dateTo: string | undefined, shipNumber: number | undefined, buyerSSN: string | undefined, recieverSSN: string | undefined, sellerSSN: string | undefined) {
  try {
    const user = await authService.getUser();
    let url = `${API_BASE_URL}reports/catch-news?harbourId=${harbourID}`;
    if (pageNumber) {
      url = `${url}&pageNumber=${pageNumber}`;
    }
    if (dateFrom) {
      url = `${url}&dateFrom=${dateFrom}`;
    }
    if (dateTo) {
      url = `${url}&dateTo=${dateTo}`;
    }
    if (shipNumber) {
      url = `${url}&shipNumber=${shipNumber}`;
    }
    if (buyerSSN) {
      url = `${url}&buyerSSN=${buyerSSN}`;
    }
    if (recieverSSN) {
      url = `${url}&recieverSSN=${recieverSSN}`;
    }
    if (sellerSSN) {
      url = `${url}&sellerSSN=${sellerSSN}`;
    }
    const response = await axios.get<ReportsApiResponse>(url, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response.data.catchNews;
  } catch (error) {
    errorHandler(error);
    return [];
  }
}

export async function getTransactionsReport(harbourID: number, pageNumber: number | undefined, dateFrom: string | undefined, dateTo: string | undefined, shipNumber: number | undefined, buyerSSN: string | undefined, recieverSSN: string | undefined, sellerSSN: string | undefined, fishId: number | undefined, gearId: number | undefined) {
  try {
    const user = await authService.getUser();
    let url = `${API_BASE_URL}reports/transactions?harbourId=${harbourID}`;
    if (pageNumber) {
      url = `${url}&pageNumber=${pageNumber}`;
    }
    if (dateFrom) {
      url = `${url}&dateFrom=${dateFrom}`;
    }
    if (dateTo) {
      url = `${url}&dateTo=${dateTo}`;
    }
    if (shipNumber) {
      url = `${url}&shipNumber=${shipNumber}`;
    }
    if (buyerSSN) {
      url = `${url}&buyerSSN=${buyerSSN}`;
    }
    if (recieverSSN) {
      url = `${url}&recieverSSN=${recieverSSN}`;
    }
    if (sellerSSN) {
      url = `${url}&sellerSSN=${sellerSSN}`;
    }
    if (fishId) {
      url = `${url}&fishId=${fishId}`;
    }
    if (gearId) {
      url = `${url}&gearId=${gearId}`;
    }
    const response = await axios.get<ReportsApiResponse>(url, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response.data.transactions;
  } catch (error) {
    errorHandler(error);
    return [];
  }
}

export async function getCatchSumReport(harbourID: number, dateFrom: string | undefined, dateTo: string | undefined, shipNumber: number | undefined, buyerSSN: string | undefined, recieverSSN: string | undefined, sellerSSN: string | undefined) {
  try {
    const user = await authService.getUser();
    let url = `${API_BASE_URL}reports/catch-sum?harbourId=${harbourID}`;
    if (dateFrom) {
      url = `${url}&dateFrom=${dateFrom}`;
    }
    if (dateTo) {
      url = `${url}&dateTo=${dateTo}`;
    }
    if (shipNumber) {
      url = `${url}&shipNumber=${shipNumber}`;
    }
    if (buyerSSN) {
      url = `${url}&buyerSSN=${buyerSSN}`;
    }
    if (recieverSSN) {
      url = `${url}&recieverSSN=${recieverSSN}`;
    }
    if (sellerSSN) {
      url = `${url}&sellerSSN=${sellerSSN}`;
    }
    const response = await axios.get<ReportsApiResponse>(url, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response.data.catchSum;
  } catch (error) {
    errorHandler(error);
    return [];
  }
}

export async function getCatchValueReport(harbourID: number, dateFrom: string | undefined, dateTo: string | undefined) {
  try {
    const user = await authService.getUser();
    let url = `${API_BASE_URL}reports/catch-value?harbourId=${harbourID}`;
    if (dateFrom) {
      url = `${url}&dateFrom=${dateFrom}`;
    }
    if (dateTo) {
      url = `${url}&dateTo=${dateTo}`;
    }
    const response = await axios.get<ReportsApiResponse>(url, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response.data.catchValue;
  } catch (error) {
    errorHandler(error);
    return [];
  }
}

export async function getCatchCompareReport(traceId: number) {
  try {
    const user = await authService.getUser();
    const response = await axios.get<ReportsApiResponse>(`${API_BASE_URL}reports/compare-catch/${traceId}`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response.data.comparison;
  } catch (error) {
    errorHandler(error);
  }
}

export async function getContacts(name?: string, ssn?: string): Promise<Contact[]> {
  try {
    const user = await authService.getUser();
    let url = `${API_BASE_URL}contacts`;
    if (name || ssn) {
      url = `${url}?`;
      if (name) {
        url = `${url}Name=${name}`;
      }
      if (ssn) {
        if (name) {
          url = `${url}&`;
        }
        url = `${url}SSN=${ssn}`;
      }
    }
    const response = await axios.get<ContactApiResponse>(url, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response.data.contacts;
  } catch (error) {
    errorHandler(error);
    return [];
  }
}

export async function getUser(redirectLogin: boolean): Promise<User | null> {
  try {
    const user = await authService.getUser();
    const response = await axios.get<User>(`${API_BASE_URL}users/current`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response.data;
  } catch (error: any) {
    errorHandler(error, redirectLogin);
    return null;
  }
}

export async function getUserUnhandled(): Promise<User | null> {
  const user = await authService.getUser();
  const response = await axios.get<User>(`${API_BASE_URL}users/current`, {
    headers: {
      Authorization: user?.access_token
    }
  });
  return response.data;
}

export async function getUserHarbours(): Promise<UserHarbours[] | null> {
  try {
    const user = await authService.getUser();
    const response = await axios.get<UserHarboursApiResponse>(`${API_BASE_URL}users/current/harbours`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response.data.harbours;
  } catch (error: any) {
    errorHandler(error);
    return null;
  }
}

export async function changeUserHarbour(change: ChangeHarbourBody) {
  try {
    const user = await authService.getUser();
    const response = await axios.patch(`${API_BASE_URL}users/current/harbour`, change, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response;
  } catch (error: any) {
    errorHandler(error);
  }
}

export async function postWeight(body: PostCatchBody, weightNoteId: number) {
  try {
    const user = await authService.getUser();
    const response = await axios.post(`${API_BASE_URL}weight-notes/${weightNoteId}/weights`, body, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response;
  } catch (error: any) {
    errorHandler(error);
  }
}

export async function deleteWeight(weightId: number) {
  try {
    const user = await authService.getUser();
    const response = await axios.delete(`${API_BASE_URL}weights/${weightId}`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response;
  } catch (error: any) {
    errorHandler(error);
  }
}

export async function getCatchCombinations(): Promise<CatchCombination[] | null> {
  try {
    const user = await authService.getUser();
    const response = await axios.get<ReferenceDataApiResponse>(`${API_BASE_URL}catch-combination`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response.data.combinations;
  } catch (error: any) {
    errorHandler(error);
    return [];
  }
}

export async function deleteDeduction(weightId: number | undefined, deductionId: number) {
  try {
    const user = await authService.getUser();
    const response = await axios.delete(`${API_BASE_URL}weights/${weightId}/deduction/${deductionId}`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response;
  } catch (error: any) {
    errorHandler(error);
  }
}

export async function postDeduction(body: PostDeductionBody, weightId: number | undefined) {
  try {
    const user = await authService.getUser();
    const response = await axios.post(`${API_BASE_URL}weights/${weightId}/deductions`, body, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response;
  } catch (error: any) {
    errorHandler(error);
  }
}

export async function patchDeduction(weightId: number, deductionId: number, change: PostDeductionBody) {
  try {
    const user = await authService.getUser();
    const response = await axios.patch(`${API_BASE_URL}weights/${weightId}/deductions/${deductionId}`, change, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response;
  } catch (error: any) {
    errorHandler(error);
  }
}

export async function deleteLanding(landingId: number) {
  try {
    const user = await authService.getUser();
    const response = await axios.delete(`${API_BASE_URL}landings/${landingId}`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response;
  } catch (error: any) {
    errorHandler(error);
  }
}

export async function postLanding(body: LandingSubmit) {
  try {
    const user = await authService.getUser();
    const response = await axios.post(`${API_BASE_URL}landings`, body, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response;
  } catch (error: any) {
    errorHandler(error);
  }
}

export async function patchLanding(landingId: number, change: LandingSubmit) {
  try {
    const user = await authService.getUser();
    const response = await axios.patch(`${API_BASE_URL}landings/${landingId}`, change, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response;
  } catch (error: any) {
    errorHandler(error);
  }
}

export async function deleteWeightNote(weightNoteId: number) {
  try {
    const user = await authService.getUser();
    const response = await axios.delete(`${API_BASE_URL}weight-notes/${weightNoteId}`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response;
  } catch (error: any) {
    errorHandler(error);
  }
}

export async function patchWeightNote(weightNoteId: number, change: WeightNoteSubmit) {
  try {
    const user = await authService.getUser();
    const response = await axios.patch(`${API_BASE_URL}weight-notes/${weightNoteId}`, change, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response;
  } catch (error: any) {
    errorHandler(error);
  }
}

export async function postWeightNote(landingId: number, body: WeightNoteSubmit) {
  try {
    const user = await authService.getUser();
    const response = await axios.post(`${API_BASE_URL}landings/${landingId}/weight-notes`, body, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response;
  } catch (error: any) {
    errorHandler(error);
  }
}

export async function patchWeight(weightId: number, change: PostCatchBody) {
  try {
    const user = await authService.getUser();
    const response = await axios.patch(`${API_BASE_URL}weights/${weightId}`, change, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response;
  } catch (error: any) {
    errorHandler(error);
  }
}

export async function getPermits(): Promise<Permit[]> {
  try {
    const user = await authService.getUser();
    const response = await axios.get<PermitApiResponse>(`${API_BASE_URL}permits`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response.data.permits;
  } catch (error: any) {
    errorHandler(error);
    return [];
  }
}

export async function getWeightNoteAsPDF(weightNoteId: number): Promise<any> {
  try {
    const user = await authService.getUser();
    const response = await axios.get(`${API_BASE_URL}weight-notes/${weightNoteId}/pdf`, {
      headers: {
        Authorization: user?.access_token
      },
      responseType: 'blob'
    });
    return response.data;
  } catch (error: any) {
    errorHandler(error);
  }
}

export async function getEmails(harbourId: number | undefined): Promise<Email[]> {
  try {
    const user = await authService.getUser();
    const response = await axios.get<EmailApiResponse>(`${API_BASE_URL}emails?harbourId=${harbourId}`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response.data.emails;
  } catch (error) {
    errorHandler(error);
    return [];
  }
}

export async function postEmail(body: Email) {
  try {
    const user = await authService.getUser();
    const response = await axios.post(`${API_BASE_URL}emails`, body, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response;
  } catch (error: any) {
    errorHandler(error);
  }
}

export async function deleteEmail(emailId: number) {
  try {
    const user = await authService.getUser();
    const response = await axios.delete(`${API_BASE_URL}emails/${emailId}`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response;
  } catch (error: any) {
    errorHandler(error);
  }
}

export async function patchEmail(emailId: number, body: Email) {
  try {
    const user = await authService.getUser();
    const response = await axios.patch(`${API_BASE_URL}emails/${emailId}`, body, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response;
  } catch (error: any) {
    errorHandler(error);
  }
}

export async function patchEmailGroup(groupId: number, body: EmailGroupPost) {
  try {
    const user = await authService.getUser();
    const response = await axios.patch(`${API_BASE_URL}emails/groups/${groupId}`, body, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response;
  } catch (error: any) {
    errorHandler(error);
  }
}

export async function sendEmailWeightNote(emailSendBody: EmailSend) {
  try {
    const user = await authService.getUser();
    const response = await axios.post(`${API_BASE_URL}emails/send/weight-note`, emailSendBody, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response;
  } catch (error: any) {
    errorHandler(error);
  }
}

export async function getEmailGroups(harbourId: number | undefined): Promise<EmailGroup[]> {
  try {
    const user = await authService.getUser();
    const response = await axios.get<EmailGroupApiResponse>(`${API_BASE_URL}emails/groups?harbourId=${harbourId}`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response.data.emailGroups;
  } catch (error) {
    errorHandler(error);
    return [];
  }
}

export async function postEmailGroup(body: EmailGroupPost) {
  try {
    const user = await authService.getUser();
    const response = await axios.post(`${API_BASE_URL}emails/groups`, body, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response;
  } catch (error: any) {
    errorHandler(error);
  }
}

export async function postEmailToGroup(groupId: number, emailId: number) {
  try {
    const user = await authService.getUser();
    const response = await axios.post(`${API_BASE_URL}emails/${emailId}/groups/${groupId}`, {}, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response;
  } catch (error: any) {
    errorHandler(error);
  }
}

export async function deleteEmailFromGroup(groupId: number, emailId: number) {
  try {
    const user = await authService.getUser();
    const response = await axios.delete(`${API_BASE_URL}emails/${emailId}/groups/${groupId}`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response;
  } catch (error: any) {
    errorHandler(error);
  }
}

export async function deleteEmailGroup(groupId: number) {
  try {
    const user = await authService.getUser();
    const response = await axios.delete(`${API_BASE_URL}emails/groups/${groupId}`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response;
  } catch (error: any) {
    errorHandler(error);
  }
}

export async function deleteVehicle(vehicleId: number) {
  try {
    const user = await authService.getUser();
    const response = await axios.delete(`${API_BASE_URL}vehicles/${vehicleId}`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response;
  } catch (error: any) {
    errorHandler(error);
  }
}

export async function postVehicle(body: VehicleSubmit) {
  try {
    const user = await authService.getUser();
    const response = await axios.post(`${API_BASE_URL}vehicles`, body, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response;
  } catch (error: any) {
    errorHandler(error);
  }
}

export async function patchVehicle(vehicleId: number, body: VehicleSubmit) {
  try {
    const user = await authService.getUser();
    const response = await axios.patch(`${API_BASE_URL}vehicles/${vehicleId}`, body, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response;
  } catch (error: any) {
    errorHandler(error);
  }
}

export async function getPossibleTraceIds(landingId?: number, shipRegistrationNumber?: number, landingDate?: string, dateRange?: number): Promise<TraceIds[]> {
  try {
    const user = await authService.getUser();
    let url = `${API_BASE_URL}landings/possible-trace`;
    const queryParams = [];
    if (landingId) {
      queryParams.push(`landingId=${landingId}`);
    }
    if (shipRegistrationNumber) {
      queryParams.push(`shipRegistrationNumber=${shipRegistrationNumber}`);
    }
    if (landingDate) {
      queryParams.push(`landingDate=${landingDate}`);
    }
    if (dateRange) {
      queryParams.push(`dateRange=${dateRange}`);
    }
    if (queryParams?.length > 0) {
      url += `?${queryParams.join('&')}`;
    }
    const response = await axios.get<TraceIdsApiResponse>(url, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response.data.possibleTraceIds;
  } catch (error: any) {
    errorHandler(error);
    return [];
  }
}

export async function getDeviations(): Promise<Deviation[]> {
  try {
    const user = await authService.getUser();
    const response = await axios.get<DeviationApiResponse>(`${API_BASE_URL}deviations`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response.data.deviations;
  } catch (error: any) {
    errorHandler(error);
    return [];
  }
}

export async function patchDeviation(deviationId: number, change: DeviationEdit) {
  try {
    const user = await authService.getUser();
    const response = await axios.patch(`${API_BASE_URL}deviations/${deviationId}`, change, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response;
  } catch (error: any) {
    errorHandler(error);
  }
}

export async function CheckForDeviation(weightNoteId: number): Promise<ReWeightingDeviation> {
  try {
    const user = await authService.getUser();
    const response = await axios.get<ReWeightingDeviation>(`${API_BASE_URL}weight-notes/${weightNoteId}/check-deviation`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response.data;
  } catch (error: any) {
    errorHandler(error);
    return {} as ReWeightingDeviation;
  }
}