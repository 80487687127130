import React, { useState } from 'react';
import ReWeightingWeights from '../ReWeightingWeights/ReWeightingWeights';
import WeightNote from '../../../../Models/WeightNoteModels/WeightNote';
import CatchCombination from '../../../../Models/CatchRegistrationModels/CatchCombination';
import FishingArea from '../../../../Models/CatchRegistrationModels/FishingArea';
import StorageMethod from '../../../../Models/CatchRegistrationModels/StorageMethod';
import Destiny from '../../../../Models/CatchRegistrationModels/Destiny';
import FishingStock from '../../../../Models/CatchRegistrationModels/FishingStock';
import Condition from '../../../../Models/CatchRegistrationModels/Condition';
import { ContainerCard, ReWeightingOverviewWrapper } from './ReWeightingInfo.styled';
import { Button, Tab, Tabs, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { tabsClasses } from '@mui/material/Tabs';
import GppGoodIcon from '@mui/icons-material/GppGood';
import GppBadIcon from '@mui/icons-material/GppBad';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import BusinessIcon from '@mui/icons-material/Business';
import PhishingIcon from '@mui/icons-material/Phishing';
import PersonIcon from '@mui/icons-material/Person';
import WarningIcon from '@mui/icons-material/Warning';
import dayjs from 'dayjs';
import { LoadingButton } from '@mui/lab';
import ReWeightingLanding from '../../../../Models/ReWeightingModels/ReWeightingLanding';
import User from '../../../../Models/UserModels/User';
import ConfirmCloseWeightNote from '../ConfirmCloseWeightNote/ConfirmCloseWeightNote';
import { CheckDeviation, closeWeightNote } from '../../../../Services/EndurvigtunAPIService';
import ReWeightingDeviation from '../../../../Models/ReWeightingModels/ReWeightingDeviation';
import { useConfirm } from 'material-ui-confirm';
import ReWeightingWeightNoteEdit from '../../../../Models/ReWeightingModels/ReWeightingWeightNoteEdit';
import { logError } from '../../../../Helpers/LogError';

interface ReWeightingInfoProps {
  user: User;
  selectedLanding: ReWeightingLanding;
  selectedWeightNote: WeightNote;
  loading: boolean;
  refetchLandings: (pageNumber: number, refetching: boolean) => void;
  showSnackbar: (message: string, severity: string) => void;
  navigationValue: number;
  catchCombinations: CatchCombination[];
  fishingAreas: FishingArea[];
  storageMethods: StorageMethod[];
  destinies: Destiny[];
  fishingStocks: FishingStock[];
  conditions: Condition[];
}

const ReWeightingInfo: React.FC<ReWeightingInfoProps> = (props) => {
  const [confirmCloseWeightNoteOpen, setConfirmCloseWeightNoteOpen] = useState(false);
  const [deviation, setDeviation] = useState<ReWeightingDeviation>();
  const [loading, setLoading] = useState(false);
  const confirm = useConfirm();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const desktopView = !isMobile && !isTablet;

  const toggleConfirmCloseWeightNote = () => {
    setConfirmCloseWeightNoteOpen(!confirmCloseWeightNoteOpen);
  };

  const handleCloseWeightNote = async () => {
    setLoading(true);
    try {
      const deviationResponse = await CheckDeviation(props.selectedWeightNote?.id);
      setDeviation(deviationResponse);
      if (deviationResponse?.fromHarbourWeightNote !== null && deviationResponse?.fromReWeighterWeightNote !== null) {
        toggleConfirmCloseWeightNote();
      } else {
        closeConfirm();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      logError(error);
    }
  };

  const closeConfirm = () => {
    confirm({
      title: 'Ertu alveg viss?',
      description: `Þetta mun ljúka endurvigtun. Þú munt ekki geta breytt skráningu eftir að henni er lokið.`,
      confirmationText: 'Ljúka',
      cancellationText: 'Hætta við',
      confirmationButtonProps: { variant: 'contained', color: 'success' },
      cancellationButtonProps: { variant: 'outlined' },
    })
      .then(() => closeTheWeightNote())
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => { });
  }

  const closeTheWeightNote = async () => {
    setLoading(true);
    try {
      if (props.user?.isReweighingUser) {
        const body: ReWeightingWeightNoteEdit = {
          deviationReason: "",
        };
        await closeWeightNote(props.selectedWeightNote?.id, body);
        props.refetchLandings(1, true);
      }
      props.showSnackbar('Endurvigtun lokið!', 'success');
      setLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setLoading(false);
      if (error.response && error.response.data && typeof error.response.data !== 'object') {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');
      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp!', 'error');
      }
    }
  }

  const addWorkingDays = (date: Date, days: number) => {
    let currentDate = dayjs(date);
    let addedDays = 0;

    while (addedDays < days) {
      currentDate = currentDate.add(1, 'day');

      // Skip weekends (Saturday and Sunday)
      if (currentDate.day() !== 0 && currentDate.day() !== 6) {
        addedDays++;
      }
    }

    return currentDate;
  };

  const reWeightingHeader = (
    <div style={{ width: '100%', display: 'flex', flexDirection: desktopView ? 'row' : 'column', justifyContent: desktopView ? 'space-between' : 'center', gap: '1em', paddingBottom: '0.5em' }}>
      <Typography variant='h6' textAlign={'center'} >
        <b>Vigtarnóta {props?.selectedWeightNote?.id ? '#' + props?.selectedWeightNote?.id : ''}</b>
      </Typography>

      {!props.selectedWeightNote?.isClosed && props.selectedLanding && (
        <Tooltip title={(addWorkingDays(props.selectedLanding?.landingStarts, props.selectedWeightNote.wholeWeight ? 5 : 2).endOf('day') < dayjs()) ?
          'Tíminn er útrunninn, Hafið samband við höfn eða Fiskistofu.' : 'Sendir endurvigtunartölur til Fiskistofu'}>
          <span>
            <LoadingButton
              variant="outlined"
              color="success"
              onClick={handleCloseWeightNote}
              startIcon={<GppGoodIcon />}
              disabled={props.loading || (addWorkingDays(props.selectedLanding?.landingStarts, props.selectedWeightNote.wholeWeight ? 5 : 2).endOf('day') < dayjs())}
              loading={loading}
              fullWidth
            >
              <span>Ljúka endurvigtun</span>
            </LoadingButton>
          </span>
        </Tooltip>
      )}
    </div>
  );

  const harbourInfo =
    (<span style={{ whiteSpace: 'pre-line', fontSize: '1.1em' }}>
      {props.selectedLanding?.harbour ?
        "Höfn\n\n" +
        props.selectedLanding?.harbour?.harbourName + "\n" +
        props.selectedLanding?.harbour?.homeAddress + "\n" +
        props.selectedLanding?.harbour?.postalCode + "\n" +
        props.selectedLanding?.harbour?.phoneNumber :
        "Höfn"}
    </span>);

  const reWeightingInfo = (
    <span style={{
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5em',
    }}>

      <Tabs
        variant="scrollable"
        value={0}
        allowScrollButtonsMobile
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 },
          },
          '& .MuiTabs-scroller': {
            '& .MuiTabs-flexContainer': {
              gap: '0.5em',
            }
          },
          '& .MuiTabs-indicator': {
            backgroundColor: 'white',
          },
        }}
      >

        <Tab disableRipple style={{ padding: 0, minWidth: 'fit-content' }}
          label={
            <Tooltip title="Staða endurvigtunar" arrow enterDelay={200}>
              <Button
                disableRipple
                component="span"
                variant="contained"
                size="medium"
                startIcon={props.selectedWeightNote?.isClosed ? <GppGoodIcon /> : <GppBadIcon />}
                disableElevation
                color={props.selectedWeightNote?.isClosed ? 'success' : 'primary'}
                sx={{ textTransform: 'none', borderRadius: 1, cursor: 'default', minWidth: !desktopView ? 'fit-content' : '' }}
              >
                {props.selectedWeightNote?.isClosed ? 'Lokuð' : 'Opin'}
              </Button>
            </Tooltip>
          } />

        <Tab disableRipple style={{ padding: 0, minWidth: 'fit-content' }}
          label={
            <Tooltip title="Skráð á höfn" arrow enterDelay={200}>
              <Button
                component="span"
                variant="outlined"
                size={!desktopView ? 'large' : 'medium'}
                startIcon={<AccessTimeFilledIcon />}
                disableElevation
                color="primary"
                sx={{
                  textTransform: 'none', borderRadius: 10,
                  color: theme.palette.mode === 'dark' ? 'white' : 'black',
                  borderColor: 'lightgray',
                  cursor: 'default',
                  minWidth: 'fit-content',
                  '&:hover': {
                    color: theme.palette.mode === 'dark' ? 'white' : 'black',
                    borderColor: 'darkgray',
                    backgroundColor: '#f8f8f8',
                  }
                }}
              >
                {props.selectedLanding.landingStarts ? dayjs(new Date(props.selectedLanding?.landingStarts)).format('DD.MM.YYYY') : ''}
              </Button>
            </Tooltip>} />

        <Tab disableRipple style={{ padding: 0, minWidth: 'fit-content' }}
          label={
            <Tooltip title="Skila fyrir lok þessa dags" arrow enterDelay={200}>
              <Button
                component="span"
                variant="outlined"
                size={!desktopView ? 'large' : 'medium'}
                startIcon={<WarningIcon />}
                disableElevation
                color="primary"
                sx={{
                  textTransform: 'none', borderRadius: 10,
                  color: theme.palette.mode === 'dark' ? 'white' : 'black',
                  borderColor: 'lightgray',
                  cursor: 'default',
                  minWidth: 'fit-content',
                  '&:hover': {
                    color: theme.palette.mode === 'dark' ? 'white' : 'black',
                    borderColor: 'darkgray',
                    backgroundColor: '#f8f8f8',
                  }
                }}
              >
                {!props.selectedLanding?.landingStarts ? '' :
                  dayjs(addWorkingDays(props.selectedLanding?.landingStarts, props.selectedWeightNote.wholeWeight ? 5 : 2)).format('DD.MM.YYYY')}
              </Button>
            </Tooltip>} />

        <Tab disableRipple style={{ padding: 0, minWidth: 'fit-content' }}
          label={
            <Tooltip title="Skip" arrow enterDelay={200}>
              <Button
                component="span"
                variant="outlined"
                size={!desktopView ? 'large' : 'medium'}
                startIcon={<DirectionsBoatIcon />}
                disableElevation
                color="primary"
                sx={{
                  textTransform: 'none', borderRadius: 10,
                  color: theme.palette.mode === 'dark' ? 'white' : 'black',
                  borderColor: 'lightgray',
                  cursor: 'default',
                  minWidth: 'fit-content',
                  '&:hover': {
                    color: theme.palette.mode === 'dark' ? 'white' : 'black',
                    borderColor: 'darkgray',
                    backgroundColor: '#f8f8f8',
                  }
                }}
              >
                {props.selectedLanding.ship ? props.selectedLanding?.ship?.shipRegistrationNumber + ' - ' + props.selectedLanding?.ship?.name : ''}
              </Button>
            </Tooltip>} />

        <Tab disableRipple style={{ padding: 0, minWidth: 'fit-content' }}
          label={
            <Tooltip
              title={harbourInfo}
              arrow
              enterDelay={200}>
              <Button
                component="span"
                variant="outlined"
                size={!desktopView ? 'large' : 'medium'}
                startIcon={<BusinessIcon />}
                disableElevation
                color="primary"
                sx={{
                  textTransform: 'none', borderRadius: 10,
                  color: theme.palette.mode === 'dark' ? 'white' : 'black',
                  borderColor: 'lightgray',
                  cursor: 'default',
                  minWidth: 'fit-content',
                  '&:hover': {
                    color: theme.palette.mode === 'dark' ? 'white' : 'black',
                    borderColor: 'darkgray',
                    backgroundColor: '#f8f8f8',
                  }
                }}
              >
                {props.selectedLanding.harbour ? props.selectedLanding?.harbour?.harbourName : ''}
              </Button>
            </Tooltip>} />

        {props.selectedWeightNote?.gear &&
          <Tab disableRipple style={{ padding: 0, minWidth: 'fit-content' }}
            label={
              <Tooltip title="Veiðarfæri" arrow enterDelay={200}>
                <Button
                  component="span"
                  variant="outlined"
                  size={!desktopView ? 'large' : 'medium'}
                  startIcon={<PhishingIcon />}
                  disableElevation
                  color="primary"
                  sx={{
                    textTransform: 'none', borderRadius: 10,
                    color: theme.palette.mode === 'dark' ? 'white' : 'black',
                    borderColor: 'lightgray',
                    minWidth: 'fit-content',
                    cursor: 'default',
                    '&:hover': {
                      color: theme.palette.mode === 'dark' ? 'white' : 'black',
                      borderColor: 'darkgray',
                      backgroundColor: '#f8f8f8',
                    }
                  }}
                >
                  {props.selectedWeightNote.gear ? props.selectedWeightNote.gear.name : ''}
                </Button>
              </Tooltip>
            } />
        }

        {(props.selectedWeightNote?.buyer) &&
          <Tab disableRipple style={{ padding: 0, minWidth: 'fit-content' }}
            label={
              <Tooltip title={<span style={{ whiteSpace: 'pre-line', fontSize: '1.1em' }}>{'Kaupandi\n\n' + props.selectedWeightNote.buyer.name + "\n" + props.selectedWeightNote.buyer.ssn + "\n" + props.selectedWeightNote.buyer.homeAddress + "\n" + props.selectedWeightNote.buyer.postalCodeName}</span>} arrow enterDelay={200}>
                <Button
                  component="span"
                  variant="outlined"
                  size={!desktopView ? 'large' : 'medium'}
                  startIcon={<PersonIcon />}
                  disableElevation
                  color="primary"
                  sx={{
                    textTransform: 'none', borderRadius: 10,
                    color: theme.palette.mode === 'dark' ? 'white' : 'black',
                    borderColor: 'lightgray',
                    cursor: 'default',
                    minWidth: 'fit-content',
                    '&:hover': {
                      color: theme.palette.mode === 'dark' ? 'white' : 'black',
                      borderColor: 'darkgray',
                      backgroundColor: '#f8f8f8',
                    }
                  }}
                >
                  {props.selectedWeightNote.buyer ? props.selectedWeightNote.buyer.name : ''}
                </Button>
              </Tooltip>
            } />
        }

      </Tabs>
    </span>
  );

  return (
    <ReWeightingOverviewWrapper
      style={{
        width: desktopView ? 'calc(75% - 1em)' : '100%',
        margin: '1em 1em 1em 0em',
      }}>

      <ConfirmCloseWeightNote
        user={props.user}
        open={confirmCloseWeightNoteOpen}
        toggleOpen={toggleConfirmCloseWeightNote}
        selectedWeightNote={props.selectedWeightNote}
        showSnackbar={props.showSnackbar}
        refetchLandingsReWeighter={props.refetchLandings}
        deviation={deviation ? deviation : null}
      />

      <ContainerCard
        style={{
          flex: desktopView ? '1 1 1' : '',
          borderRadius: desktopView ? '0.75em' : '0',
          boxShadow: desktopView ? '' : 'none',
          border: desktopView ? '' : 'none',
          padding: '1em',
          backgroundColor: theme.palette.mode === "dark" ? "#272727" : undefined,
        }}>

        {reWeightingHeader}
        {reWeightingInfo}
        <ReWeightingWeights
          user={props.user}
          selectedWeightNote={props.selectedWeightNote}
          loading={props.loading}
          showSnackbar={props.showSnackbar}
          catchCombinations={props.catchCombinations}
          fishingAreas={props.fishingAreas}
          storageMethods={props.storageMethods}
          destinies={props.destinies}
          fishingStocks={props.fishingStocks}
          conditions={props.conditions}
          refetchLanding={props.refetchLandings}
        />
      </ContainerCard>
    </ReWeightingOverviewWrapper >
  );
};

export default ReWeightingInfo;